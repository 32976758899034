import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibModule } from 'projects/common/src/lib/lib.module';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { PersonEventHistoryComponent } from './components/details/event-history/person-event-history.component';
import { FirmEventHistoryComponent } from './components/details/event-history/firm-event-history.component';
import { ManualPaymentDialogue } from './components/details/registration/manual-payment/manual-payment.dialogue';
import { ManualPaymentDialogueDirective } from './components/details/registration/manual-payment/manual-payment.directive';
import { EventHistoryComponent } from './components/details/event-history/event-history.component';
import { EventHistoryListComponent } from './components/details/event-history/event-history-list/event-history-list.component';
import { EventHistoryNoteDialogue } from './components/details/event-history/event-history-note-dialogue/event-history-note.dialogue';
import { EventHistoryNoteDialogueDirective } from './components/details/event-history/event-history-note-dialogue/event-history-note.dialogue.directive';
import { ListingComponent } from './components/listing/listing.component';
import { LicenseStatusSummaryComponent } from './components/license-status-summary/license-status-summary.component';
import { SideLinksComponent } from './components/side-links/side-links.component';
import { ApplicationReviewBoxComponent } from './components/details/registration/application-review-box/application-review-box.component';
import { LicenseListComponent } from './components/details/registration/license-list/license-list.component';
import { NcarbOfferLoginComponent } from './components/details/ncarb-offer-login/ncarb-offer-login.component';
import { FirmTypeSelectorComponent } from './components/firm-type-selector/firm-type-selector.component';
import { MoralCharacterComponent } from './components/details/moral-character/moral-character.component';
import { PaymentListWrapperComponent } from './components/details/payment/payment-list-wrapper.component';
import { RosterComponent } from './components/details/roster/roster.component';
import { DetailComponent } from './components/details/detail.component';
import { FilterIndicatorComponent } from './components/filter-indicator/filter-indicator.component';
import { DateRangeDisplayComponent } from './components/date-range/date-range-display/date-range-display.component';
import { ProfessionalReferenceDialog } from '../person/registration/professional-references/professional-reference.dialog';
import { PointOfContactListComponent } from './components/details/business-info/point-of-contact/point-of-contact-list.component';
import { LicenseButtons } from '../person/registration/registration-aside.component';
import { ApproveLicenseComponent } from '../person/registration/approve-license.component';

const components = [
  DateRangeComponent,
  DateRangeDisplayComponent,
  ListingComponent,
  SideLinksComponent,
  LicenseStatusSummaryComponent,
  EventHistoryComponent,
  PersonEventHistoryComponent,
  FirmEventHistoryComponent,
  EventHistoryListComponent,
  EventHistoryNoteDialogue,
  EventHistoryNoteDialogueDirective,
  ApproveLicenseComponent,
  ManualPaymentDialogue,
  LicenseButtons,
  ManualPaymentDialogueDirective,
  ApplicationReviewBoxComponent,
  LicenseListComponent,
  NcarbOfferLoginComponent,
  FirmTypeSelectorComponent,
  MoralCharacterComponent,
  PaymentListWrapperComponent,
  PointOfContactListComponent,
  RosterComponent,
  DetailComponent,
  FilterIndicatorComponent,
  ProfessionalReferenceDialog,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, LibModule],
  exports: components,
  entryComponents: [EventHistoryNoteDialogue, ManualPaymentDialogue, ProfessionalReferenceDialog],
})
export class SharedManagerModule {}
