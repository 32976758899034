import { UpdatableModel } from '../updatable-model';
import { Experience } from './experience';
import { NcarbOverviewItem } from './ncarb-overview-item';

export class ExperienceSummary extends UpdatableModel<ExperienceSummary> {
  completedOn: Date;
  totalYearsOfExperience: number;
  totalApprovedHours: number;
  satisfiedVia: string;
  programName: string;
  isSatisfied: boolean;
  experiences: Experience[];

  asOverview(): NcarbOverviewItem {
    let item = new NcarbOverviewItem();
    item.category = 'Experience';
    item.isSatisfied = this.isSatisfied;
    item.title = this.programName + ' ' + (this.isSatisfied ? 'Complete' : 'Program');
    item.details.push(this.totalApprovedHours + ' total approved hours');
    item.details.push(this.totalYearsOfExperience + ' years of experience');
    return item;
  }
}
