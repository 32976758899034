import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-indicator',
  templateUrl: './filter-indicator.component.html',
  styleUrls: ['./filter-indicator.component.scss'],
})
export class FilterIndicatorComponent {
  @Input() name;
  @Output() cancel = new EventEmitter();

  doCancel(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.cancel.emit();
  }
}
