import { Component } from '@angular/core';

import { EventHistoryComponent } from './event-history.component';
import { PersonFull } from 'projects/common/src/lib/models/person-full';

import { EventHistoryService } from 'projects/common/src/lib/services/api/event-history.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { AuditService } from 'projects/common/src/lib/services/api/audit.service';
import { PersonService } from 'projects/common/src/lib/services/api/person.service';
import { PersonDetailService } from 'projects/common/src/lib/services/person-detail.service';

@Component({
  selector: 'app-person-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
})
export class PersonEventHistoryComponent extends EventHistoryComponent<PersonFull, PersonService> {
  constructor(
    protected eventHistoryService: EventHistoryService,
    protected notificationService: NotificationService,
    protected auditService: AuditService,
    protected personDetailService: PersonDetailService
  ) {
    super(eventHistoryService, notificationService, auditService);
    this.detailService = personDetailService;
  }
}
