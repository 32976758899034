<lib-titled-card highlight="true" *ngIf="applicationUnderReview">
  <aside>
    <ng-content></ng-content>
  </aside>
  <h3>{{ applicationUnderReview.type | spaceCapCase }} Application</h3>
  <table mat-table [dataSource]="datasource" class="simple-table equal-widths last-col-longer no-bottom-line">
    <ng-container matColumnDef="orderNumber">
      <th mat-header-cell *matHeaderCellDef>Confirmation<br />Number</th>
      <td mat-cell *matCellDef="let application">{{ application.orderNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="licenseType">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let application">{{ application.licenseTypeName }}</td>
    </ng-container>
    <ng-container matColumnDef="residency">
      <th mat-header-cell *matHeaderCellDef>Residency</th>
      <td mat-cell *matCellDef="let application">{{ application.residency }}</td>
    </ng-container>
    <ng-container matColumnDef="profession">
      <th mat-header-cell *matHeaderCellDef>Profession</th>
      <td mat-cell *matCellDef="let application">{{ application.professionDescription }}</td>
    </ng-container>
    <ng-container matColumnDef="licenseOrigin">
      <th mat-header-cell *matHeaderCellDef>Origin</th>
      <td mat-cell *matCellDef="let application">{{ application.licenseOrigin }}</td>
    </ng-container>
    <ng-container matColumnDef="blank">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let application"></td>
    </ng-container>
    <ng-container matColumnDef="transmittalDate">
      <th mat-header-cell *matHeaderCellDef>Transmittal<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.createdOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="applicationDate">
      <th mat-header-cell *matHeaderCellDef>Application<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.submittedOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef>Payment<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.orderCompletedOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef width="1">Application<br />Status</th>
      <td mat-cell *matCellDef="let application">
        <app-status-selector
          [applicationId]="application.id"
          [noPrompt]="true"
          (statusChanged)="onApplicationStatusChanged($event, application)"
        >
        </app-status-selector>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  </table>
</lib-titled-card>
