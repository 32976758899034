<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let poc">
      <a routerLink="/person/{{ poc.person.id }}">{{ poc.person.name.full }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let poc">{{ poc.title }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let poc">{{ poc.person.email }}</td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Phone Number</th>
    <td mat-cell *matCellDef="let poc">{{ poc.phone.number }}</td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">{{noItemsDisplay}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>