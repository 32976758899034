<header>
  <h3>History Log</h3>
  <nav>
    <ul>
      <li [class.active]="type$.value == _type" *ngFor="let _type of eventHistoryTypes">
        <button (click)="switchType(_type)" mat-button class="auto">
          <mat-icon *ngIf="_type">{{ _type | lookup: 'eventHistoryIcon' }}</mat-icon>
          {{ _type | lookup: 'eventHistoryLabel' }}
        </button>
      </li>
    </ul>
  </nav>
  <div>
    <button class="auto alt" mat-button (click)="dialogue.open()">+ Add Note</button>
    <app-event-history-note
      #dialogue="dialogue"
      [subjectName]="subjectName"
      [subjectId]="_id"
      (eventSave)="onNoteAction($event, 'add')"
    ></app-event-history-note>
  </div>
</header>
<app-event-history-list
  [eventHistoryType$]="type$"
  [subjectName]="subjectName"
  [list$]="events$"
  (editEvent)="onNoteAction($event, 'update')"
  (deleteEvent)="onNoteAction($event, 'delete')"
></app-event-history-list>
