import { UpdatableModel } from '../updatable-model';
import { Education } from './education';
import { NcarbOverviewItem } from './ncarb-overview-item';
import { formatNcarbDate } from '../../utility/date-utils';

export class EducationSummary extends UpdatableModel<EducationSummary> {
  isSatisfied: boolean;
  education: Education[];

  asOverview(): NcarbOverviewItem {
    let item = new NcarbOverviewItem();
    item.category = 'Education';
    item.isSatisfied = this.isSatisfied;
    let schools = this.education;
    if (schools.length) {
      schools = schools.sort((a, b) => (a.graduationDate < b.graduationDate ? 1 : -1));
      schools = schools.sort((a, b) => (a.isComplete < b.isComplete ? 1 : -1));
      schools = schools.sort((a, b) => (a.isNAABOrCACB < b.isNAABOrCACB ? 1 : -1));
      let school = schools[0];
      item.title = school.description;
      item.details.push(school.degreeName);
      if (school.isNAABOrCACB) {
        item.details.push(school.accreditation);
      }
      if (school.isVerified) {
        item.details.push('Verified ' + formatNcarbDate(school.verificationDate));
      }
    }
    return item;
  }
}
