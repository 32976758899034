import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isProdModeEnabled } from 'projects/common/src/lib/constants/environment';
import { initDataDogLogging } from 'projects/common/src/lib/utility/datadog';
import { AppManagerModuleFunc } from './app/app-manager.module';
import { TenantService } from 'projects/common/src/lib/services/api/tenant.service';

const tenantService = new TenantService();
tenantService.loadFromApi('staff').then(() => {
  if (isProdModeEnabled()) {
    enableProdMode();
  }

  initDataDogLogging();

  platformBrowserDynamic()
    .bootstrapModule(AppManagerModuleFunc(tenantService))
    .catch(err => console.log(err));
});
