import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { Application } from 'projects/common/src/lib/models/applications/application';

@Component({
  selector: 'app-application-review-box',
  templateUrl: './application-review-box.component.html',
  styleUrls: ['./application-review-box.component.scss'],
})
export class ApplicationReviewBoxComponent implements OnChanges {
  @Input() applicationUnderReview: Application;
  @Input() columns = [];

  @Input()
  updateApplicationStatus: (application: Application, status: string) => Promise<any>;

  @Output() change = new EventEmitter();
  datasource: Application[] = [];
  constructor(private notificationService: NotificationService) {}

  ngOnChanges(): void {
    if (this.applicationUnderReview) {
      const currentApplicationUnderReview = this.datasource.length ? this.datasource[0] : {};
      if (currentApplicationUnderReview !== this.applicationUnderReview) {
        this.datasource = [this.applicationUnderReview];
      }
    } else {
      if (this.datasource.length) {
        this.datasource = [];
      }
    }
  }

  async onApplicationStatusChanged(status) {
    this.notificationService.notifyWait('Updating status');
    if (!this.updateApplicationStatus) {
      console.error('updateApplicationStatus input func not set');
      return;
    }
    try {
      await this.updateApplicationStatus(this.applicationUnderReview, status);
      this.notificationService.notifySuccess('Status updated successfully');
    } catch (error) {
      console.error(error);
    }
    //want to emit change regard-less.
    //on error; then refresh the page to display the correct status.
    //on success; then refresh the page if things switched buckets
    this.change.emit();
  }
}
