import { Component, ContentChild, Input, OnChanges, TemplateRef } from '@angular/core';
import { LicenseCertificateVariant } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { ILicense } from 'projects/common/src/lib/models/i-license';

const noItem = {};
const noItemRow = [noItem];

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss'],
})
export class LicenseListComponent implements OnChanges {
  @Input() licenses: ILicense[];
  @Input() columns: string[];
  @Input() impersonate: () => {} = null;
  @Input() download: () => {} = null;
  @Input() sendLicense: () => {} = null;
  @Input() canDownloadLicense: boolean;
  @Input() licenseCertificateVariants: LicenseCertificateVariant[];

  datasource: (ILicense | object)[];

  @ContentChild(TemplateRef) statusTemplate: TemplateRef<any>;

  async ngOnChanges(): Promise<any> {
    if (this.licenses?.length) {
      if (this.datasource != this.licenses) {
        this.datasource = this.licenses;
      }
    } else {
      this.datasource = noItemRow;
    }
  }

  isEmpty = (_, itemRow) => (itemRow === noItem ? true : false);
}
