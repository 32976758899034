import { Component, Input } from '@angular/core';
import { FirmPointOfContact } from 'projects/common/src/lib/models/firm-point-of-contact';
import { ListComponent } from 'projects/common/src/lib/components/list';

@Component({
  selector: 'point-of-contact-list',
  templateUrl: './point-of-contact-list.component.html',
  styleUrls: ['./point-of-contact-list.component.scss'],
})
export class PointOfContactListComponent extends ListComponent<FirmPointOfContact> {
  @Input() noItemsDisplay: string;
  columns: string[] = ['name', 'title', 'email', 'phone'];
  constructor() {
    super();
  }
}
