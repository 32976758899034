<div mat-dialog-title>
  {{professionalReference.id ? 'Edit' : 'Add'}} Professional Reference
  <h2>{{subjectName}}</h2>
</div>
<div mat-dialog-content>
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>First Name</mat-label>
      <input #firstName matInput [(ngModel)]="professionalReference.firstName" required />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Last Name</mat-label>
      <input #lastName matInput [(ngModel)]="professionalReference.lastName" required />
    </mat-form-field>
  </div>
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="professionalReference.email" />
    </mat-form-field>
  </div>
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>Date Received</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="professionalReference.dateReceived" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<p mat-dialog-actions class="reverse">
  <button
    mat-button
    type="submit"
    color="primary"
    [mat-dialog-close]="professionalReference"
    [disabled]="!(firstName.value && lastName.value)"
  >
    Save
  </button>
  <button mat-button type="button" color="accent" [mat-dialog-close]="false">Cancel</button>
</p>
