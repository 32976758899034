import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { GlobalSearchDialogue } from './global-search-dialogue/global-search-dialogue';
import { NcarbAuthService } from './shared/services/ncarb-auth.service';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { Auth0Settings } from 'projects/common/src/lib/services/api/Auth0Settings';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  title = 'Licensing Manager';
  isLoggedIntoNcarb = false;
  archLink = { path: '/individuals', label: 'Architects' };
  navLinks = [
    { path: '/applications', label: 'Applications' },
    this.archLink,
    { path: '/firms', label: 'Firms' },
    { path: '/reporting', label: 'Reporting' },
  ];
  ncarbAvailable: boolean;
  private unsubscribeOnDestroy: Subscription;
  constructor(
    public auth: AuthService,
    public ncarbAuth: NcarbAuthService,
    private globalSearchDialog: MatDialog,
    private router: Router,
    private constants: JurisdictionConstants,
    settings: TenantSettings,
    public auth0: Auth0Settings,
    protected titleService: Title
  ) {
    this.ncarbAvailable = settings.professions.some(p => p === 'Architect');
    if (!settings.doesLicenseFirms) {
      this.navLinks = this.navLinks.filter(l => l.label != 'Firms');
    }
    titleService.setTitle(`${constants.acronym} Licensing System`);
  }

  ngOnDestroy(): void {
    this.unsubscribeOnDestroy.unsubscribe();
  }

  ngOnInit(): void {
    this.unsubscribeOnDestroy = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(e => this.globalSearchDialog.closeAll());

    this.isLoggedIntoNcarb = this.ncarbAuth.isAuthenticated();

    this.archLink.label = this.constants.architectTabName;

    window.addEventListener('storage', e => this.onStorageChange(e));
  }

  openGlobalSearchDialog() {
    if (!this.auth.isAuthenticated()) {
      return false;
    }
    this.globalSearchDialog.open(GlobalSearchDialogue, {
      width: '90vw',
      maxWidth: '940px',
      position: { top: '20px' },
    });
    return false;
  }

  toggleNcarb() {
    if (this.ncarbAuth.isAuthenticated()) {
      this.ncarbAuth.logout();
      this.isLoggedIntoNcarb = false;
    } else {
      this.ncarbAuth.openDialogue();
    }
  }

  onStorageChange(e: StorageEvent) {
    if (e.key === 'access_token') {
      this.isLoggedIntoNcarb = this.ncarbAuth.isAuthenticated();
    }
  }
}
