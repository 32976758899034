import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NcarbAuthService } from '../shared/services/ncarb-auth.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Injectable()
export class NcarbConnectionGuard implements CanActivate {
  constructor(
    private ncarbAuth: NcarbAuthService,
    private settings: TenantSettings
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    //route.parent.data.person can be undefined if you refresh
    if (!this.settings.professions.some(p => p === 'Architect') || !route.parent.data.person?.ncarbNumber) {
      //no need to try ncarbLogin if no architect profession or no ncarbNumber
      return of(true);
    }
    return this.ncarbAuth.tryLogin();
  }
}
