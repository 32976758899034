import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Transaction } from 'projects/common/src/lib/models/transaction';

@Component({
  selector: 'app-payment-list-wrapper',
  templateUrl: './payment-list-wrapper.component.html',
  styleUrls: ['./payment-list-wrapper.component.scss'],
})
export class PaymentListWrapperComponent implements OnInit {
  payments$: Observable<Transaction[]>;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.payments$ = of(this.route.snapshot.data.payments as Transaction[]);
  }
}
