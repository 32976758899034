<header>
  <section>
    <div>
      <span class="link" *ngIf="auth.isAuthenticated()">
        <a [href]="auth0.delegatedManagementUrl" target="_blank"> Auth0 Dashboard </a>
      </span>
      <span class="ncarb-integration link" *ngIf="auth.isAuthenticated() && ncarbAvailable" (click)="toggleNcarb()">
        NCARB
        <mat-icon *ngIf="isLoggedIntoNcarb">link</mat-icon>
        <mat-icon *ngIf="!isLoggedIntoNcarb">link_off</mat-icon>
      </span>
      <app-ncarb-silent-load [tryOncePerDay]="true"></app-ncarb-silent-load>
      <span class="login-email" *ngIf="auth.isAuthenticated()">{{ auth.claims?.email }}</span>
      <button id="login" *ngIf="!auth.isAuthenticated()" (click)="auth.login()">Login</button>
      <button id="logout" *ngIf="auth.isAuthenticated()" (click)="auth.logout()">Log Out</button>
    </div>
  </section>
  <section>
    <div>
      <a [routerLink]="['/home']">
        <img lib-image image="logo-reversed.png" width="154" />
      </a>
      <nav>
        <a
          *ngFor="let link of navLinks"
          [routerLink]="link.path"
          routerLinkActive
          #rla="routerLinkActive"
          [class.active]="rla.isActive"
          [class.muted]="link.inactive"
          >{{ link.label }}</a
        >
        <a href="#" (click)="openGlobalSearchDialog()" class="search">
          <mat-icon class="inline">search</mat-icon>
        </a>
      </nav>
      <h1>{{ 'manager-site-name' | labelSwapper | async }}</h1>
    </div>
    <lib-progress-bar></lib-progress-bar>
  </section>
</header>
<main>
  <div>
    <router-outlet></router-outlet>
  </div>
</main>
<lib-env-banner></lib-env-banner>
