import { UpdatableModel } from '../updatable-model';
import { IArchitectLicense } from '../i-architect-license';
import { NcarbOverviewItem } from './ncarb-overview-item';
import { numberLabel } from '../../utility/string-utils';

export class NcarbRegistration extends UpdatableModel<NcarbRegistration> implements IArchitectLicense {
  stateText: string;
  number: string;
  receivedDate: Date;
  expirationDate: Date;
  verifiedBy: string;
  isActive: boolean;

  get jurisdiction() {
    return this.stateText;
  }

  get issueDate() {
    return this.receivedDate;
  }
  get status() {
    return this.isActive ? 'Active' : 'Inactive';
  }

  static getOverview(registrations: NcarbRegistration[]): NcarbOverviewItem {
    let item = new NcarbOverviewItem();
    item.category = 'Registrations';
    let activeRegistrations = registrations.filter(r => r.isActive);
    item.isSatisfied = !!activeRegistrations.length;
    item.title = numberLabel(activeRegistrations.length, 'Registration');
    return item;
  }
}
