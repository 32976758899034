import { UpdatableModel } from '../updatable-model';
import { ExperienceSummary } from './experience-summary';
import { EducationSummary } from './education-summary';
import { ExaminationSummary } from './examination-summary';
import { NcarbRegistration } from './ncarb-registration';
import { NcarbRecordOverviewItem } from './ncarb-record-overview-item';

export class NcarbRecordOverview extends UpdatableModel<NcarbRecordOverview> {
  items: NcarbRecordOverviewItem[];

  update(init?: Partial<NcarbRecordOverview>) {
    this.items = [];
    if (!init || !init.items) {
      return;
    }
    for (let item of init.items) {
      this.items.push(new NcarbRecordOverviewItem(item));
    }
  }

  static FromSummaries(
    educationSummary: EducationSummary,
    experienceSummary: ExperienceSummary,
    examSummary: ExaminationSummary,
    registrations: NcarbRegistration[]
  ) {
    let items = [
      educationSummary.asOverview(),
      experienceSummary.asOverview(),
      examSummary.asOverview(),
      NcarbRegistration.getOverview(registrations),
    ];
    return new NcarbRecordOverview({ items });
  }
}
