<table mat-table class="simple-table with-detail-row" [dataSource]="items$">
  <ng-container matColumnDef="reported">
    <th mat-header-cell *matHeaderCellDef>Reported</th>
    <td mat-cell *matCellDef="let r">{{ r.updatedOn | date: 'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Report Type</th>
    <td mat-cell *matCellDef="let r">{{ r.name }}</td>
  </ng-container>
  <ng-container matColumnDef="answers">
    <th mat-header-cell *matHeaderCellDef>Affirmative<br />Answers</th>
    <td mat-cell *matCellDef="let r">{{ r.answers.length }}</td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef width="1"></th>
    <td mat-cell *matCellDef="let r">
      <mat-icon *ngIf="r.answers.length">expand_more</mat-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let r" [attr.colspan]="columns.length">No Responses on file</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let r; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let r; columns: columns; when: noAnswers"></tr>
  <tr
    mat-row
    *matRowDef="let r; columns: columns"
    matRipple
    #detailDir="cdkDetailRow"
    [cdkDetailRow]="r"
    [cdkDetailRowTpl]="tpl"
    [cdkDetailRowPreRender]="true"
    class="pointer"
  ></tr>
</table>

<ng-template #tpl let-rr>
  <tr class="mat-row detail-row">
    <td class="mat-cell" colspan="4">
      <div *ngFor="let a of rr.answers" class="answer-review">
        <h3 class="question-text" [innerHtml]="a.questionText"></h3>
        <strong>Explanation</strong>
        <p>
          {{ a.explanation }}
        </p>
        <lib-upload-button-with-file-list
          title="Related Documentation"
          label="Files"
          [aggregateRootId]="aggregateRootId"
          [subjectId]="a.id"
          documentType="MoralCharacter"
        >
        </lib-upload-button-with-file-list>
        <lib-notes [subjectId]="a.id"></lib-notes>
      </div>
    </td>
  </tr>
</ng-template>
