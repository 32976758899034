<table mat-table class="simple-table with-detail-row" [dataSource]="items$">
  <ng-container matColumnDef="icon">
    <td mat-cell *matCellDef="let event" width="1">
      <mat-icon>{{ event.type | lookup: 'eventHistoryIcon' }}</mat-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="title">
    <td mat-cell *matCellDef="let event">
      {{ adjustEventTitle(event.title) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <td mat-cell *matCellDef="let event">{{ event.happenedOn | dateTimeFormat: 'date' }}</td>
  </ng-container>
  <ng-container matColumnDef="time">
    <td mat-cell *matCellDef="let event" class="text-nowrap">{{ event.happenedOn | dateTimeFormat: 'time' }}</td>
  </ng-container>
  <ng-container matColumnDef="actor">
    <td mat-cell *matCellDef="let event">{{ event.actor?.name?.firstLast || event.createdBy }}</td>
  </ng-container>
  <ng-container matColumnDef="comments">
    <td mat-cell *matCellDef="let event">
      <mat-icon *ngIf="event.commentsCount">question_answer</mat-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <td mat-cell *matCellDef="let event" width="1">
      <mat-icon>expand_more</mat-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="isLoading">
    <td mat-cell *matCellDef="let event" [attr.colspan]="columns.length">Loading...</td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <td mat-cell *matCellDef="let event" [attr.colspan]="columns.length">
      No {{ (eventHistoryType$.value | lookup: 'eventHistoryName')?.toLowerCase() || 'events' }} yet
    </td>
  </ng-container>
  <ng-container matColumnDef="hidden">
    <td mat-cell *matCellDef="let event" [attr.colspan]="columns.length" style="visibility: hidden">&nbsp;</td>
  </ng-container>
  <tr mat-row *matRowDef="let event; columns: ['isLoading']; when: loading"></tr>
  <tr mat-row *matRowDef="let event; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let event; columns: ['hidden']; when: hideMe"></tr>
  <tr
    mat-row
    *matRowDef="let event; columns: columns"
    class="event-row pointer"
    matRipple
    #detailDir="cdkDetailRow"
    [cdkDetailRow]="event"
    [cdkDetailRowTpl]="tpl"
  ></tr>
</table>

<ng-template #tpl let-event>
  <tr class="mat-row detail-row">
    <td class="mat-cell" [attr.colspan]="columns.length">
      <button
        *ngIf="event.discriminator === 'ReadyToApplyEmailSentSystemEvent'"
        mat-button
        class="resend-email"
        (click)="resendReadyToApplyEmail(event)"
      >
        Resend Email
      </button>
      <div *ngIf="event.text" class="event-text">
        <div class="event-mod float-right" *ngIf="event.type == 'Note' && isUserActor(event)">
          <mat-icon class="muted-text" [matMenuTriggerFor]="editMenu">more_horiz</mat-icon>
          <mat-menu #editMenu="matMenu">
            <button mat-menu-item (click)="dialogue.open()">Edit Note</button>
            <button mat-menu-item (click)="confirmDelete.open()">Delete Note</button>
          </mat-menu>
          <app-event-history-note
            #dialogue="dialogue"
            [subjectName]="subjectName"
            [eventHistoryNote]="event"
            (eventSave)="edit($event)"
          ></app-event-history-note>
          <lib-confirm
            #confirmDelete="confirm"
            prompt="Are you sure you want to delete this note?"
            yesText="Yes, delete it"
            noText="No, cancel"
            (yes)="delete(event)"
          >
          </lib-confirm>
        </div>
        {{ event.text }}
        <span class="muted-text" *ngIf="event.edited">(Edited)</span>
      </div>
      <table *ngIf="event.propertyChanges" class="table-property-changes">
        <tr *ngFor="let change of event.propertyChanges">
          <td>{{ change.propertyName | spaceCapCase }}:</td>
          <td>
            {{ change.oldValue }}
          </td>
          <td>
            <span *ngIf="change.oldValue">&rArr;</span>
          </td>
          <td>{{ change.newValue }}</td>
        </tr>
      </table>
      <lib-notes [subjectId]="event.id"></lib-notes>
    </td>
  </tr>
</ng-template>
