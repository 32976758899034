import { UpdatableModel } from './updatable-model';
export class ManualPayment extends UpdatableModel<ManualPayment> {
  paymentType: string;
  checkNumber: string;
  nameOnCheck: string;

  update(init?: Partial<ManualPayment>) {
    super.update(init);
  }
}
