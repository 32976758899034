import { GenericApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../notification.service';
import { Observable, forkJoin, lastValueFrom, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EducationSummary } from '../../models/ncarb/education-summary';
import { ExaminationSummary } from '../../models/ncarb/examination-summary';
import { ExperienceSummary } from '../../models/ncarb/experience-summary';
import { WorkHistory } from '../../models/ncarb/work-history';
import { NcarbRegistration } from '../../models/ncarb/ncarb-registration';
import { NcarbTransmittalDownloadInfo } from '../../models/ncarb/ncarb-transmittal-download-info';
import { ArchitectLicense } from '../../models/architect-license';
import { ArchitectApplication } from '../../models/applications/architect-application';
import { NcarbRecord } from '../../models/ncarb/ncarb-record';
import { NcarbRecordOverview } from '../../models/ncarb/ncarb-record-overview';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { NcarbSettings } from './NcarbSettings';
import { NcarbOverviewItem } from '../../models/ncarb/ncarb-overview-item';
import * as moment from 'moment';
import { ILicensedBy } from '../../models/licensed-by';

class NcarbLicenseNotification {
  constructor(license: ArchitectLicense, licensedBy: ILicensedBy) {
    const today = moment().startOf('day').format('YYYY-MM-DD');

    this.certifiedBy = licensedBy?.name;
    this.title = licensedBy?.title;
    this.ncarbNumber = license.person.ncarbNumber;
    this.submittedDate = today;
    this.verificationDate = today;
    this.stateCode = license.verifiedBy;
    this.licenseNumber = license.number;
    this.startDate = moment(license.issueDate).startOf('day').format('YYYY-MM-DD');
    this.endDate = moment(license.expirationDate).startOf('day').format('YYYY-MM-DD');
  }
  certifiedBy: string;
  title: string;
  ncarbNumber: number;
  submittedDate: string;
  stateCode: string;
  licenseNumber: string;
  startDate: string;
  endDate: string;
  isDerogatory: boolean; //never used?
  verificationDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NcarbApiService extends GenericApiService {
  private stateCode: string;
  private stateName: string;
  constructor(
    http: HttpClient,
    notificationService: NotificationService,
    private ncarb: NcarbSettings,
    settings: TenantSettings
  ) {
    super(ncarb.publicApi, http, notificationService);
    this.stateCode = settings.stateCode;
    this.stateName = settings.stateName;
  }

  private async getPersonIdByNcarbNumber(ncarbNumber: number): Promise<string> {
    try {
      const person = await this.getAsync<{ personId }>(`v1.0/recordHolders/byRecordNumber/${ncarbNumber}`, false);
      return person.personId;
    } catch (err) {
      if (err.status == 404) {
        this.notificationService.notifyFailTransient(
          'NCARB Record Number ' + ncarbNumber + ' was not found to exist per NCARB'
        );
      }
      throw err;
    }
  }

  //wrapper around `get` with error handler that calls `this.softHandleNcarb500`
  private getNcarb = (url: string, context: 'general' | 'exams' = 'general'): Observable<Object> =>
    this.get(url).pipe(catchError(err => this.softHandleNcarb500(err, context)));

  private getExaminationSummary(personId: string): Observable<ExaminationSummary> {
    return this.getNcarb(`v1.0/recordHolders/${personId}/passedExams`, 'exams').pipe(
      map(d => {
        var combinedExams = [];
        var exam = new ExaminationSummary(d);
        for (var examType in exam.passedExams) combinedExams = combinedExams.concat(exam.passedExams[examType]);
        exam.passedExams = combinedExams;
        return exam;
      })
    );
  }

  private getExperienceSummary = (personId: string): Observable<ExperienceSummary> =>
    this.getNcarb(`v1.0/recordHolders/${personId}/experiences/${this.stateCode}`).pipe(map(d => new ExperienceSummary(d)));

  private getEducationSummary = (personId: string): Observable<EducationSummary> =>
    this.getNcarb(`v1.0/recordHolders/${personId}/education`).pipe(map(d => new EducationSummary(d)));

  private getWorkHistory = (personId: string): Observable<WorkHistory[]> =>
    this.getNcarb(`v1.0/recordHolders/${personId}/workHistory`).pipe(
      map((workArray: WorkHistory[]) => workArray.map(e => new WorkHistory(e)))
    );

  private getRegistrations = (personId: string): Observable<NcarbRegistration[]> =>
    this.getNcarb(`v1.0/people/${personId}/registrations`).pipe(map((d: object[]) => d.map(r => new NcarbRegistration(r))));

  private getTransmittalInfo = (personId: string): Observable<NcarbTransmittalDownloadInfo> =>
    this.getNcarb(`v1.0/people/${personId}/registrations/${this.stateCode}/transmittalUpload`).pipe(
      map(d => (d && d['id'] ? new NcarbTransmittalDownloadInfo(d, this.ncarb) : null))
    );

  //this and getNcarbRecord are only public functions
  public async sendLicenseAcknowledgement(
    ncarbNumber: number,
    license: ArchitectLicense,
    licensedBy: ILicensedBy
  ): Promise<any> {
    try {
      const personId = await this.getPersonIdByNcarbNumber(ncarbNumber);
      await this.postAsync<NcarbRegistration>(
        `v1.0/people/${personId}/registrations`,
        new NcarbLicenseNotification(license, licensedBy),
        false
      ).then(_ => wait(3000));
      this.notificationService.notifySuccess('License Information Sent to NCARB');
    } catch (errorResponse) {
      if (errorResponse.status == 500) {
        await wait(5000); //why wait 5
        this.notificationService.notifyFail(NcarbApiService.ErrorMessages['license']);
        return null;
      }
      throw errorResponse;
    }
  }

  // this and sendLicenseAcknowledgement are only public functions
  public async getNcarbRecord(
    ncarbNumber: number,
    getLicenses: Observable<ArchitectLicense[]>,
    getApplications: Observable<ArchitectApplication[]>
  ): Promise<NcarbRecord> {
    const personId = await this.getPersonIdByNcarbNumber(ncarbNumber);

    const summaries = [
      this.getEducationSummary(personId),
      this.getExperienceSummary(personId),
      this.getExaminationSummary(personId),
      this.getRegistrations(personId),
      this.getWorkHistory(personId),
      this.getTransmittalInfo(personId),
      getLicenses,
      getApplications,
    ];
    return await lastValueFrom(
      forkJoin(summaries).pipe(
        map(
          ([
            educationSummary,
            experienceSummary,
            examinationSummary,
            registrations,
            workHistory,
            transmittalInfo,
            licenses,
            applications,
          ]: [
            EducationSummary,
            ExperienceSummary,
            ExaminationSummary,
            NcarbRegistration[],
            WorkHistory[],
            NcarbTransmittalDownloadInfo,
            ArchitectLicense[],
            ArchitectApplication[],
          ]) => {
            const hasCurrentApplication = applications.some(a => a.isActive);
            const overview = NcarbRecordOverview.FromSummaries(
              educationSummary,
              experienceSummary,
              examinationSummary,
              registrations
            );

            const ncarbRegistrationOverview: NcarbOverviewItem = overview.items[3];
            if (hasCurrentApplication) {
              ncarbRegistrationOverview.hasCurrentApplication = true;
              ncarbRegistrationOverview.details.push('1 Application');
            }

            const license = licenses.find(l => l.profession === 'Architect');
            if (license) {
              let ncarbHomeReg = registrations.find(r => r.jurisdiction == this.stateName);
              if (!ncarbHomeReg) {
                ncarbRegistrationOverview.errors.push(`${this.stateName} License not registered with NCARB`);
              } else if (license.isActive && !ncarbHomeReg.isActive) {
                ncarbRegistrationOverview.errors.push(`Active ${this.stateName} License not active with NCARB`);
              }
            }

            const transmittalLink = transmittalInfo ? transmittalInfo.link : '';

            const otherRegistrations = registrations.filter(r => r.stateText != this.stateName);
            return {
              overview,
              educationSummary,
              examinationSummary,
              experienceSummary,
              workHistory,
              registrations,
              otherRegistrations,
              transmittalLink,
            };
          }
        ),
        catchError(err => this.handlePersonNotFound(err, ncarbNumber))
      )
    );
  }

  private handlePersonNotFound(errorResponse: HttpErrorResponse, ncarbNumber: number): Observable<any> {
    if (errorResponse.status == 404) {
      this.notificationService.notifyFailTransient('NCARB Record Number ' + ncarbNumber + ' was not found to exist per NCARB');
    }
    return throwError(() => errorResponse);
  }

  static ErrorMessages = {
    general: 'Data not available: NCARB Api issue',
    exams: 'Data not available: NCARB Api issue',
    license: 'License issued, but error sending license info to NCARB. You must login to NCARB and verify there.',
  };

  private softHandleNcarb500(errorResponse: HttpErrorResponse, context = 'general'): Observable<any> {
    if (errorResponse.status == 500) {
      let errorMessage = NcarbApiService.ErrorMessages[context];
      if (context == 'license') {
        setTimeout(_ => this.notificationService.notifyFail(errorMessage), 5000);
        return of(null);
      }
      this.notificationService.notifyFailTransient(errorMessage);
      return of(null);
    }
    return throwError(() => errorResponse);
  }
}

function wait(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
