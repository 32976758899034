import { Component, Input } from '@angular/core';
import { SideLink } from './side-link';

@Component({
  selector: 'app-side-links',
  templateUrl: './side-links.component.html',
  styleUrls: ['./side-links.component.scss'],
})
export class SideLinksComponent {
  @Input() links: SideLink[];
}
