import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from 'projects/common/src/lib/services/auth/auth-guard.service';
import { StaffAuthorizingComponent } from './staff-authorizing/staff-authorizing.component';
import { MainComponent } from './main.component';
import { LandingComponent } from './access/landing/landing.component';
import { UnauthorizedComponent } from './access/unauthorized.component';
import { PageNotFoundComponent } from './access/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { AddressLookupsResolve } from 'projects/common/src/lib/resolves/address-lookups.resolve';
import { ReviewRoutes } from 'projects/common/src/lib/modules/application-review/application-review.module';

const reviewRoutes = ReviewRoutes(
  'individual-application-review/:personId/:applicationId',
  'firm-application-review/:firmId/:applicationId',
  AuthGuard
);

const routes: Routes = [
  {
    path: 'form',
    children: [reviewRoutes.architectReviewRoute, reviewRoutes.firmReviewRoute],
  },
  { path: 'ncarb', loadChildren: () => import('./ncarb/ncarb.module').then(m => m.NcarbModule) },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      {
        path: 'applications',
        loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
      },
      {
        path: 'individuals',
        loadChildren: () => import('./architects/architects.module').then(m => m.ArchitectsModule),
      },
      {
        path: 'person',
        loadChildren: () => import('./person/person.module').then(m => m.PersonModule),
        canActivate: [AuthGuard],
        resolve: {
          addressLookups: AddressLookupsResolve,
        },
      },
      {
        path: 'firms',
        loadChildren: () => import('./firms/firms.module').then(m => m.FirmsModule),
      },
      {
        path: 'firm',
        loadChildren: () => import('./firm/firm.module').then(m => m.FirmModule),
        canActivate: [AuthGuard],
        resolve: {
          addressLookups: AddressLookupsResolve,
        },
      },
      {
        path: 'reporting',
        loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
      },
      { path: 'landing', component: LandingComponent },
      { path: 'authorizing', component: StaffAuthorizingComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class ManagerRoutingModule {}
