import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const buildLicensedByForm = (formBuilder: FormBuilder, { name, title }: ILicensedBy): FormGroup =>
  formBuilder.group({
    name: [name, Validators.required],
    title: [title, Validators.required],
  });

export const formToLicensedByValue = (form: FormGroup): ILicensedBy => form?.value;

export interface ILicensedBy {
  name: string;
  title: string;
}
