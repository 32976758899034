<lib-titled-card>
  <h3><ng-content></ng-content></h3>
  <aside>
    <license-buttons
      [impersonate]="impersonate"
      [download]="download"
      [sendLicense]="sendLicense"
      [canDownloadLicense]="canDownloadLicense"
      [licenseCertificateVariants]="licenseCertificateVariants"
    ></license-buttons>
  </aside>
  <table mat-table [dataSource]="datasource" class="simple-table equal-widths first-col-longer last-col-longer">
    <ng-container matColumnDef="jurisdiction">
      <th mat-header-cell *matHeaderCellDef>Jurisdiction</th>
      <td mat-cell *matCellDef="let license">
        {{ license.jurisdiction }}
      </td>
    </ng-container>
    <ng-container matColumnDef="firmLicenseType">
      <th mat-header-cell *matHeaderCellDef>Firm Type</th>
      <td mat-cell *matCellDef="let license">{{ license.typeShortName }}</td>
    </ng-container>
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>License<br />Number</th>
      <td mat-cell *matCellDef="let license">{{ license.number }}</td>
    </ng-container>
    <ng-container matColumnDef="residency">
      <th mat-header-cell *matHeaderCellDef>Residency</th>
      <td mat-cell *matCellDef="let license">{{ license.residency }}</td>
    </ng-container>
    <ng-container matColumnDef="issueDate">
      <th mat-header-cell *matHeaderCellDef>Issue Date</th>
      <td mat-cell *matCellDef="let license">{{ license.issueDate | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef>
        Expiration <br />
        Date
      </th>
      <td mat-cell *matCellDef="let license">{{ license.expirationDate | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="verified">
      <th mat-header-cell *matHeaderCellDef>Verified</th>
      <td mat-cell *matCellDef="let license">
        {{ license.verifiedBy }}
      </td>
    </ng-container>
    <ng-container matColumnDef="profession">
      <th mat-header-cell *matHeaderCellDef>Profession</th>
      <td mat-cell *matCellDef="let license">
        {{ license.profession }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>License<br />Status</th>
      <td mat-cell *matCellDef="let license">
        <ng-container
          *ngTemplateOutlet="statusTemplate ?? defaultStatusTemplate; context: { $implicit: license }"
        ></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="noItems">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let license" [attr.colspan]="columns.length">No {{ title }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: isEmpty"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  </table>
</lib-titled-card>
<ng-template let-license #defaultStatusTemplate>
  <span>{{ license.status }}</span>
</ng-template>
