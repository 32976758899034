import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogDirective } from 'projects/common/src/lib/components/dialog/dialog.directive';
import { Application } from 'projects/common/src/lib/models/applications/application';
import { ArchitectLicense } from 'projects/common/src/lib/models/architect-license';
import { ILicense } from 'projects/common/src/lib/models/i-license';
import { formToLicensedByValue } from 'projects/common/src/lib/models/licensed-by';
import { NcarbApiService } from 'projects/common/src/lib/services/api/ncarb-api.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { PreferencesService } from 'projects/common/src/lib/services/preferences.service';
import { firstValueFrom } from 'rxjs';
import { NcarbAuthService } from 'src/app/shared/services/ncarb-auth.service';

@Component({
  selector: 'approve-license',
  exportAs: 'dialog',
  templateUrl: './approve-license.component.html',
})
export class ApproveLicenseComponent {
  //only used for architects and firms
  @Input() applicationUnderReview: Application;
  @Input() licensePreview: ILicense;
  @Input() approveLicenseApi: (license: ILicense) => Promise<any>;
  //only used for architects
  @Input() ncarbNumber: number;
  @Input() licensedByForm: FormGroup;

  constructor(
    private notificationService: NotificationService,
    private ncarbApiService: NcarbApiService,
    private ncarbAuthService: NcarbAuthService,
    private preferencesService: PreferencesService
  ) {}

  @ViewChild(DialogDirective)
  dialogDirective: DialogDirective;

  open = (): Promise<boolean> =>
    this.dialogDirective.open().then(async result => {
      if (result) {
        return await this.approveLicense();
      }
      return result;
    });

  private approveLicense = async (): Promise<any> => {
    this.saveNameAndTitleInPreferencesIfNecessary();
    var actioning =
      this.applicationUnderReview.type == 'License'
        ? 'Issuing'
        : this.applicationUnderReview.type == 'Renewal'
        ? 'Renewing'
        : this.applicationUnderReview.type == 'EmeritusRenewal'
        ? 'Renewing'
        : 'Reinstating';

    var actioned =
      this.applicationUnderReview.type == 'License'
        ? 'issued'
        : this.applicationUnderReview.type == 'Renewal'
        ? 'renewed'
        : this.applicationUnderReview.type == 'EmeritusRenewal'
        ? 'renewed'
        : 'reinstated';

    this.notificationService.notifyWait(`${actioning} License`);

    const license = await this.approveLicenseApi(this.licensePreview);
    //const license = this.licensePreview; //for testing

    const isSuccessful = await this.sendLicenseToNcarbIfNecessary(license as ArchitectLicense);
    if (isSuccessful) {
      //if false is returned then sendLicenseToNcarbIfNecessary(license) handled the notificationService failed message
      this.notificationService.notifySuccess(`License ${actioned} successfully!`);
    }
  };
  private saveNameAndTitleInPreferencesIfNecessary() {
    if (this.licensedByForm) {
      const licenseBy = formToLicensedByValue(this.licensedByForm);
      this.preferencesService.preferences.name = licenseBy.name;
      this.preferencesService.preferences.title = licenseBy.title;
    }
  }
  private async sendLicenseToNcarbIfNecessary(license: ArchitectLicense): Promise<boolean> {
    //can we move the rest of this function to bus?
    if (license.lastRenewalDate || license.profession !== 'Architect') {
      //if(license.lastRenewalDate is truthy, this is a renewal or reinstatement)
      return true;
    }

    if (!this.ncarbNumber) {
      this.notificationService.notifyFail('Could not send license information to NCARB: No NCARB Number on record.');
      return false;
    }

    if (!this.ncarbAuthService.isAuthenticated()) {
      this.notificationService.notifyWait(`Logging into NCARB`);
      const isLoggedIntoNcarb = await firstValueFrom(this.ncarbAuthService.tryLogin());
      if (isLoggedIntoNcarb) {
        this.notificationService.notifySuccess(`Successfully logged into NCARB`);
      } else {
        this.notificationService.notifyFail(NcarbApiService.ErrorMessages.license);
        return false;
      }
    }
    try {
      await this.ncarbApiService.sendLicenseAcknowledgement(
        this.ncarbNumber,
        license,
        formToLicensedByValue(this.licensedByForm)
      );
      return true;
    } catch (err) {
      this.notificationService.notifyFail(NcarbApiService.ErrorMessages.license);

      console.error(err);
      return false;
    }
  }
}
