import * as moment from 'moment';
import { UpdatableModel } from 'projects/common/src/lib/models/updatable-model';
import { DateRangeSelectionOption } from './date-range-selection-option';

export class DateRangeSelection extends UpdatableModel<DateRangeSelection> {
  option: string;
  since?: moment.Moment;
  until?: moment.Moment;

  update(init?: Partial<DateRangeSelection>) {
    super.update(init);
    this.option = this.option || DateRangeSelectionOption.AllTime;
  }
}
