export enum DateRangeSelectionOption {
  AllTime = 'AllTime',
  PastDay = 'PastDay',
  PastWeek = 'PastWeek',
  PastMonth = 'PastMonth',
  Past3Months = 'Past3Months',
  Past6Months = 'Past6Months',
  PastYear = 'PastYear',
  Custom = 'Custom',
}
