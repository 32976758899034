import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized.component';

@NgModule({
  declarations: [LandingComponent, PageNotFoundComponent, UnauthorizedComponent],
  imports: [CommonModule],
})
export class AccessModule {}
