<mat-form-field appearance="standard">
  <lib-autocomplete
    #auto="autocomplete"
    [textDisplay]="true"
    [displayWith]="getLookupName"
    [search]="autocompleteGlobalSearch"
    (optionSelected)="onSelect($event)"
  >
    <input type="search" matInput [matAutocomplete]="auto.autocomplete" [ngModel]="text" [placeholder]="placeholder" />
    <ng-template let-item let-highlight="highlight">
      <lib-lookup-option [item]="item" [showStatus]="true" [highlight]="highlight"> </lib-lookup-option>
    </ng-template>
  </lib-autocomplete>
  <mat-icon matSuffix (click)="auto.clear()" *ngIf="auto.typed | async">close</mat-icon>
</mat-form-field>
<button mat-button><mat-icon>search</mat-icon></button>
