import { HostBinding, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LinkInfo } from 'projects/common/src/lib/models/links/link-info';
import { IEntityService } from 'projects/common/src/lib/services/api/i-entity.service';
import { DetailService } from 'projects/common/src/lib/services/detail.service';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';

//used by firm.component and person.component
@Component({
  selector: 'app-detail-component',
  template: '',
})
export class DetailComponent<TEntity, TEntityService extends IEntityService<TEntity>> extends BaseComponent {
  @HostBinding('class.toggle-detail-component') toggleComponentClass = true;
  detailLinks$ = new BehaviorSubject<LinkInfo[]>([]);
  entity$: BehaviorSubject<TEntity>;
  detailService: DetailService<TEntity, TEntityService>;
  links: LinkInfo[];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected titleService: Title
  ) {
    super();

    this.unsubOnDestroy = router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(evt => {
      let navEvt = <NavigationEnd>evt;
      this.setTitle(navEvt.url);
    });
  }

  ngOnInit() {
    this.entity$ = this.detailService.entity$;
    this.unsubOnDestroy = this.detailLinks$.subscribe(links => {
      if (!this.route.children) {
        return; //not set in storybook
      }
      this.setTitle(this.route.children[0].routeConfig.path, links);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.setTitle('');
  }

  get entity() {
    return this.entity$.value;
  }

  get entityName() {
    return this.entity && this.entity['name'];
  }

  private setTitle(url: string, links: LinkInfo[] = null) {
    this.links = links || this.links;
    links = this.links;
    let title = this.titleService.getTitle().split(' - ')[0];
    if (this.entity$ && this.entity$.value) {
      title += ' - ' + this.entityName;
      if (url && links) {
        let paths = url.split('/');
        let path = paths[paths.length - 1];
        let page = links.filter(p => p.path == path)[0];
        if (page) {
          title += ' - ' + page.label;
        }
      }
    }
    this.titleService.setTitle(title);
  }
}
