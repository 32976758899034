<h2>Views</h2>
<nav>
  <mat-nav-list class="side-links">
    <a
      mat-list-item
      *ngFor="let link of links"
      [routerLink]="['../' + link.path]"
      routerLinkActive
      #rla="routerLinkActive"
      [class.active]="rla.isActive"
      [class.muted]="link.muted"
      >{{ link.title }}</a
    >
  </mat-nav-list>
</nav>
