export class ClaimKeys {
  static readonly authenticationMethod = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod';
  static readonly email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
  static readonly gender = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/gender';
  static readonly givenName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
  static readonly username = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
  static readonly surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
  static readonly role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  static readonly personId = 'http://identity.ncarb.org/claims/personid';
  static readonly staffId = 'http://identity.ncarb.org/claims/staffId';
  static readonly recordNumber = 'http://identity.ncarb.org/claims/recordNumber';
}
