import { Component, Input } from '@angular/core';
import { DateRangeSelection } from 'projects/common/src/lib/models/search/date-range-selection';

@Component({
  selector: 'app-date-range-display',
  templateUrl: './date-range-display.component.html',
  styleUrls: ['./date-range-display.component.scss'],
})
export class DateRangeDisplayComponent {
  @Input() dateRange: DateRangeSelection;
}
