import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventHistoryNoteDialogue } from './event-history-note.dialogue';
import { EventHistory } from 'projects/common/src/lib/models/event-history';

@Directive({
  selector: 'app-event-history-note, [app-event-history-note]',
  exportAs: 'dialogue',
})
export class EventHistoryNoteDialogueDirective {
  @Input() subjectId: string;
  @Input() subjectName: string;
  @Input() eventHistoryNote: EventHistory;
  @Output() eventSave = new EventEmitter<EventHistory>();
  dialogRef: MatDialogRef<EventHistoryNoteDialogue>;
  constructor(private dialogue: MatDialog) {}

  open() {
    this.dialogRef = this.dialogue.open(EventHistoryNoteDialogue, {
      data: {
        subjectId: this.subjectId,
        subjectName: this.subjectName,
        eventHistoryNote: this.eventHistoryNote,
      },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.eventSave.emit(result);
      }
    });
  }
}
