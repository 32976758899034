import { ClaimKeys } from './claim-keys';

export class NcarbProfile {
  personId: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  userName: string;
  roles: string[];

  isStaff: boolean;

  constructor(private profile: any) {
    this.profile = profile;
    this.personId = this.getClaim(ClaimKeys.personId);
    this.isStaff = !!this.getClaim(ClaimKeys.staffId);
    this.email = this.getClaim(ClaimKeys.email);
    this.firstName = this.getClaim(ClaimKeys.givenName);
    this.lastName = this.getClaim(ClaimKeys.surname);
    this.fullName = this.firstName + ' ' + this.lastName;
    this.userName = this.getClaim(ClaimKeys.username);
    this.roles = this.getClaims(ClaimKeys.role);
  }

  isInRole = (role: string): boolean => this.roles.includes(role);

  private getClaim(type: string): string {
    const claims = this.getClaims(type);
    return claims.length ? claims[0] : null;
  }

  private getClaims(type: string): string[] {
    const claimValues: string[] = [];
    for (const key in this.profile) {
      if (this.profile.hasOwnProperty(key)) {
        const claim = this.profile[key];
        if (claim.type === type) {
          claimValues.push(claim.value);
        }
      }
    }
    return claimValues;
  }
}
