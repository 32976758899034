import { Component } from '@angular/core';
import { GroupedListComponent } from 'projects/common/src/lib/components/list/grouped-list.component';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { FirmRosterMember } from 'projects/common/src/lib/models/firm-roster-member';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.scss'],
})
export class RosterComponent extends GroupedListComponent<FirmRosterMember> {
  columns = ['status', 'name', 'licenseNumber', 'residency', 'issueDate', 'expirationDate'];

  constructor(private settings: TenantSettings) {
    super();
  }

  getLicenseStatus(rosterMember: FirmRosterMember) {
    return rosterMember?.license?.status;
  }

  getStatusDisplay(status: string): string {
    return this.settings.getStatusLabel(status);
  }

  getStatusClass(status: string) {
    return this.settings.getCustomStatusCssClass(status, 'status-', 'unlicensed');
  }

  route(rosterMember: FirmRosterMember) {
    return ['/person', rosterMember.personId];
  }
}
