import { UpdatableModel } from './updatable-model';
import { PersonLite } from './person-lite';
import * as moment from 'moment';
import { AuditProperty } from './audit-property';
export class EventHistory extends UpdatableModel<EventHistory> {
  id: string;
  subjectId: string;
  actorId: string;
  actor: PersonLite;
  title: string;
  text: string;
  type: string;
  category: string;
  createdBy: string;
  happenedOn: Date;
  createdOn: Date;
  updatedOn: Date;
  commentsCount: number;
  propertyChanges: AuditProperty[];
  aggregateRootId: string;
  discriminator: string;

  update(init?: Partial<EventHistory>) {
    super.update(init);
    this.actor = new PersonLite(this.actor);
    this.happenedOn = moment.utc(this.happenedOn).milliseconds(0).local().toDate();
    this.createdOn = moment.utc(this.createdOn).milliseconds(0).local().toDate();
    this.updatedOn = moment.utc(this.updatedOn).milliseconds(0).local().toDate();
    this.propertyChanges = (this.propertyChanges && this.propertyChanges.map(c => new AuditProperty(c))) || null;
  }

  get edited(): boolean {
    return this.updatedOn.getTime() > this.createdOn.getTime();
  }
}
