<lib-dialog
  [yesText]="applicationUnderReview.typeAction + ' License'"
  [header]="applicationUnderReview.typeAction + ' License'"
  [formGroup]="licensedByForm"
  width="480"
>
  <ng-template>
    <h2><ng-content></ng-content></h2>
    <fieldset>
      <legend>New License Information</legend>
      <div class="d-flex justify-content-between">
        <lib-read-field [value]="licensePreview.number">License Number</lib-read-field>
        <lib-read-field [value]="licensePreview.issueDate">Issue Date</lib-read-field>
        <lib-read-field [value]="licensePreview.expirationDate">Expiration Date</lib-read-field>
      </div>
    </fieldset>
    <fieldset *ngIf="licensedByForm">
      <legend>
        NCARB Verification Information
        <mat-icon class="inline" matTooltipClass="tooltip" matTooltip="Upon issuing the license, we will notify NCARB"
          >info</mat-icon
        >
      </legend>
      <div class="flex-fields">
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>Your Name</mat-label>
          <input matInput [formControl]="licensedByForm.controls.name" required />
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>Title</mat-label>
          <input matInput [formControl]="licensedByForm.controls.title" required />
        </mat-form-field>
      </div>
    </fieldset>
  </ng-template>
</lib-dialog>
