import { OnInit, ViewChild, Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { skip, map } from 'rxjs/operators';
import { ListData } from 'projects/common/src/lib/models/search/list-data';
import { DateRangeSelection } from 'projects/common/src/lib/models/search/date-range-selection';
import { LookupService } from 'projects/common/src/lib/services/lookup.service';
import { SearchService } from './../../services/search.service';
import { ISearch } from 'projects/common/src/lib/models/search/i-search';
import { PagerComponent } from 'projects/common/src/lib/components/pager/pager.component';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';

@Component({
  selector: 'lib-listing',
  template: '',
})
export class ListingComponent<
    TObject,
    TSearch extends ISearch,
    TSearchService extends SearchService<TObject, TSearch>,
    TLookupService extends LookupService,
  >
  extends BaseComponent
  implements OnInit
{
  constructor(protected router: Router) {
    super();
  }
  search$: Observable<TSearch>;
  // results from search service
  listData$: Observable<ListData<TObject>>;
  // list from listData
  list$: Observable<TObject[]>;
  count$: Observable<Number>;
  dateRange: DateRangeSelection;
  loadOnInit = true;
  searchPlaceholder = 'Email, Name, License Number, NCARB Record Number';
  searchService: TSearchService;
  lookupService: TLookupService;
  @ViewChild(PagerComponent) pagerComponent: PagerComponent;

  ngOnInit() {
    this.searchService.params$.value.search = null;
    this.search$ = this.searchService.params$;
    this.dateRange = this.searchService.dateRange;
    this.listData$ = this.searchService.listData$;
    this.list$ = this.searchService.listData$.pipe(map(l => l.data));
    this.count$ = this.listData$.pipe(map(l => l.count));
    if (this.loadOnInit) {
      this.searchService.update({});
    }
    this.lookupService.go$.pipe(skip(1)).subscribe(s => this.onSearch(s));
    this.unsubOnDestroy = this.search$.subscribe(search => {
      this.pagerComponent && this.pagerComponent.resetPager(search.pageNumber);
    });
  }

  lookupSearch = (search: string) => this.lookupService.fetch(search);

  onDebounce(search) {
    this.searchService.update({ search } as TSearch);
  }

  onSearch(search) {
    this.searchService.update({ search } as TSearch);
  }

  onSelect(item) {
    if (item.firmId) {
      this.router.navigate(['/firm', item.lookupId]);
    } else {
      this.router.navigate(['/person', item.lookupId]);
    }
  }

  onDateRangeChanged(dateRange: DateRangeSelection) {
    this.searchService.updateDateRange(dateRange);
  }

  onStatusChanged(status) {
    this.searchService.update({ status } as TSearch);
  }

  onPageChanged(page: PageEvent) {
    this.searchService.update(
      {
        pageNumber: page.pageIndex + 1,
        pageSize: page.pageSize,
      } as TSearch,
      true
    );
  }

  onSortChanged(sortObj: Sort) {
    let sort = sortObj.direction ? sortObj.active + ' ' + sortObj.direction : null;
    this.searchService.update({ sort } as TSearch);
  }
}
