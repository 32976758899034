<mat-radio-group [(ngModel)]="selectedOption" (ngModelChange)="onRangeChanged()">
  <ul>
    <li *ngFor="let option of options" [class.rightPanel]="option == 'Custom' && customRightPanel">
      <mat-radio-button [value]="option" color="primary">
        {{ option | spaceCapCase }}
      </mat-radio-button>
      <form *ngIf="option == 'Custom'" class="custom-range" [formGroup]="form">
        <mat-form-field appearance="standard">
          <mat-datepicker-toggle matPrefix [for]="sincePicker"></mat-datepicker-toggle>
          <input
            matInput
            name="customSince"
            formControlName="customSince"
            [matDatepicker]="sincePicker"
            [max]="maxDateSince()"
          />
          <mat-datepicker #sincePicker></mat-datepicker>
        </mat-form-field>
        <span>Thru</span>
        <mat-form-field appearance="standard">
          <mat-datepicker-toggle matPrefix [for]="untilPicker"></mat-datepicker-toggle>
          <input
            matInput
            name="customUntil"
            formControlName="customUntil"
            [matDatepicker]="untilPicker"
            [min]="minDateUntil()"
          />
          <mat-datepicker #untilPicker></mat-datepicker>
        </mat-form-field>
      </form>
    </li>
  </ul>
</mat-radio-group>
