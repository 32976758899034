import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { DateRangeSelection } from 'projects/common/src/lib/models/search/date-range-selection';
import { DateRangeSelectionOption } from 'projects/common/src/lib/models/search/date-range-selection-option';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  exportAs: 'dateRange',
})
export class DateRangeComponent extends BaseComponent implements OnInit {
  @Input() context: string;
  @Input() range: DateRangeSelection;
  @Input() customRightPanel: boolean;
  @Output() rangeChanged = new EventEmitter<DateRangeSelection>();
  options: string[];
  selectedOption: string;
  today = new Date();
  form: FormGroup;
  customSince: FormControl;
  customUntil: FormControl;

  ngOnInit() {
    this.context = this.context || 'applications';
    this.options = this.contextOptions[this.context];
    this.form = new FormGroup({
      customSince: new FormControl(),
      customUntil: new FormControl(),
    });
    this.customSince = this.form.controls.customSince as FormControl;
    this.customUntil = this.form.controls.customUntil as FormControl;
    if (this.range) {
      this.selectedOption = this.range.option;
      if (this.selectedOption == DateRangeSelectionOption.Custom) {
        this.customSince.setValue(moment(this.range.since));
        this.customUntil.setValue(moment(this.range.until));
      }
    } else {
      this.selectedOption = DateRangeSelectionOption.PastMonth;
    }
    this.toggleCustomDateForm();
    this.unsubOnDestroy = this.form.valueChanges.subscribe(v => this.onCustomDateChanged());
  }

  toggleCustomDateForm() {
    if (this.selectedOption == DateRangeSelectionOption.Custom) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  maxDateSince() {
    return this.form.controls.customUntil.value || this.today;
  }

  minDateUntil() {
    return this.form.controls.customSince.value;
  }

  getDateRangeSelection(): DateRangeSelection {
    let since = null,
      until = null;
    if (this.selectedOption == DateRangeSelectionOption.Custom) {
      if (this.customSince.value) {
        since = moment(this.customSince.value);
      }
      if (this.customUntil.value) {
        until = moment(this.customUntil.value);
      }
    } else if (this.selectedOption != DateRangeSelectionOption.AllTime) {
      let [amount, unit] = this.itemUnitsAndDateBlockMap[this.selectedOption];
      since = moment().subtract(amount, unit).startOf('day');
    }
    return new DateRangeSelection({ option: this.selectedOption, since, until });
  }

  onCustomDateChanged() {
    if (this.form.valid) {
      this.triggerSelection();
    }
  }

  onRangeChanged() {
    this.toggleCustomDateForm();
    this.triggerSelection();
  }

  triggerSelection() {
    let selection = this.getDateRangeSelection();
    this.rangeChanged.emit(selection);
  }

  itemUnitsAndDateBlockMap = {
    PastDay: [1, 'days'],
    PastWeek: [1, 'weeks'],
    PastMonth: [1, 'months'],
    Past3Months: [3, 'months'],
    Past6Months: [6, 'months'],
    PastYear: [1, 'years'],
  };

  contextOptions = {
    applications: [
      DateRangeSelectionOption.PastMonth,
      DateRangeSelectionOption.Past3Months,
      DateRangeSelectionOption.Past6Months,
      DateRangeSelectionOption.PastYear,
      DateRangeSelectionOption.AllTime,
      DateRangeSelectionOption.Custom,
    ],
    reports: [
      DateRangeSelectionOption.AllTime,
      DateRangeSelectionOption.PastDay,
      DateRangeSelectionOption.PastWeek,
      DateRangeSelectionOption.PastMonth,
      DateRangeSelectionOption.PastYear,
      DateRangeSelectionOption.Custom,
    ],
  };
}
