import { Component } from '@angular/core';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { EnvironmentSettings } from 'projects/common/src/lib/services/api/tenant.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent {
  constructor(
    public settings: TenantSettings,
    public environmentSettings: EnvironmentSettings
  ) {}
}
