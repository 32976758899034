import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSearchLookupItem } from 'projects/common/src/lib/models/search/global-search-lookup-item';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchApiService } from 'projects/common/src/lib/services/api/search/search-api.service';

@Component({
  selector: 'app-global-search-dialogue',
  templateUrl: './global-search-dialogue.html',
  styleUrls: ['./global-search-dialogue.scss'],
})
export class GlobalSearchDialogue {
  @HostBinding('class.search-bar') seachBar: boolean = true;
  placeholder = 'Enter a Name, Email, License Number, or NCARB Number';
  autocompleteGlobalSearch = (search: string) => this.searchApiService.searchGlobal(search);
  getLookupName = (value: GlobalSearchLookupItem) => (value ? value.lookupName : '');
  text = '';

  constructor(
    private router: Router,
    private searchApiService: SearchApiService
  ) {}

  onSelect($event: MatAutocompleteSelectedEvent) {
    let item = $event.option.value as GlobalSearchLookupItem;
    this.router.navigate([`/${item.lookupType}`, item.lookupId]);
  }
}
