import { UpdatableModel } from '../updatable-model';
import { Examination } from './examination';
import { NcarbOverviewItem } from './ncarb-overview-item';
import { numberLabel } from '../../utility/string-utils';

export class ExaminationSummary extends UpdatableModel<ExaminationSummary> {
  jurisdictionOfInitialLicensure: string;
  completedOn: Date;
  version: string;
  passedExams: Examination[];
  isSatisfied: boolean;

  asOverview(): NcarbOverviewItem {
    let item = new NcarbOverviewItem();
    item.category = 'Examination';
    item.isSatisfied = this.isSatisfied;
    item.title = this.version + ' ' + (this.isSatisfied ? 'Complete' : 'Candidate');
    if (!this.version) {
      item.title = 'Not a Candidate';
    }
    let passedExamsCount = this.passedExams.filter(e => this.isSatisfied || e.isActive).length;
    item.details.push(numberLabel(passedExamsCount, 'Division') + ' passed');
    return item;
  }
}
