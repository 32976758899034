import { NgModule } from '@angular/core';

import { JwtModule } from '@auth0/angular-jwt';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { LibModule } from 'projects/common/src/lib/lib.module';
import { JwtConfig } from 'projects/common/src/lib/services/auth/auth-token-service';

import { ManagerRoutingModule } from './manager.routing';
import { AppComponent } from './app.component';
import { MainComponent } from './main.component';

import { AccessModule } from './access/access.module';
import { HomeComponent } from './home/home.component';
import { SharedManagerModule } from './shared/shared-manager.module';

import { CommonAppModule } from 'projects/common/src/lib/common-app.module';
import { NcarbModule } from './ncarb/ncarb.module';

import { ApplicationReviewModule } from 'projects/common/src/lib/modules/application-review/application-review.module';

import { GlobalSearchDialogue } from './global-search-dialogue/global-search-dialogue';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { XhrProgressInterceptorService } from 'projects/common/src/lib/services/xhr-progress/xhr-progress.interceptor.service';
import { StaffAuthorizingComponent } from './staff-authorizing/staff-authorizing.component';
import { TenantService } from 'projects/common/src/lib/services/api/tenant.service';
export function AppManagerModuleFunc(tenantService: TenantService) {
  @NgModule({
    declarations: [AppComponent, MainComponent, HomeComponent, GlobalSearchDialogue, StaffAuthorizingComponent],
    imports: [
      HttpClientModule,
      CommonAppModule,
      ManagerRoutingModule,
      LibModule,
      AccessModule,
      OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: [tenantService.ncarb.url],
          sendAccessToken: true,
        },
      }),
      NcarbModule,
      SharedManagerModule,
      ApplicationReviewModule,
      JwtModule.forRoot(JwtConfig()),
      Angulartics2Module.forRoot(),
    ],
    providers: [
      { provide: TenantService, useValue: tenantService },
      { provide: Angulartics2GoogleGlobalSiteTag, useClass: Angulartics2GoogleGlobalSiteTag },
      { provide: OAuthStorage, useValue: localStorage },
      { provide: HTTP_INTERCEPTORS, useClass: XhrProgressInterceptorService, multi: true },
    ],
    exports: [GlobalSearchDialogue],
    entryComponents: [GlobalSearchDialogue],
    bootstrap: [AppComponent],
  })
  class AppManagerModule {}
  return AppManagerModule;
}
