import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OAuthModule } from 'angular-oauth2-oidc';
import { LibModule } from 'projects/common/src/lib/lib.module';
import { NcarbLoginComponent } from './ncarb-login/ncarb-login.component';
import { NcarbSilentLoadComponent } from './ncarb-silent-load/ncarb-silent-load.component';
import { NcarbRoutingModule } from './ncarb.routing';
import { NcarbLoginDialogue } from './ncarb-login-dialogue/ncarb-login-dialogue';
import { NcarbNumberGuard } from './ncarb-number.guard';
import { NcarbConnectionGuard } from './ncarb-connection.guard';

@NgModule({
  imports: [OAuthModule, CommonModule, LibModule, NcarbRoutingModule],
  declarations: [NcarbLoginComponent, NcarbSilentLoadComponent, NcarbLoginDialogue],
  exports: [NcarbSilentLoadComponent, NcarbLoginDialogue],
  entryComponents: [NcarbLoginDialogue],
  providers: [NcarbNumberGuard, NcarbConnectionGuard],
})
export class NcarbModule {}
