<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="statusRow">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let group" [attr.colspan]="columns.length">
      <span class="status-word" [ngClass]="getStatusClass(group.label)">{{ this.getStatusDisplay(group.label) }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let status" [attr.colspan]="columns.length">
      {{ isLoading ? 'Loading...' : 'No results found.' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef width="1">Status</th>
    <td mat-cell *matCellDef="let rosterMember" class="status-column">
      <span
        class="status-box"
        [ngClass]="getStatusClass(getLicenseStatus(rosterMember))"
        attr.data-status-abbreviation="{{ getLicenseStatus(rosterMember) | firstLetter }}"
      ></span>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let rosterMember">
      <a [routerLink]="route(rosterMember)">{{ rosterMember.name.firstLast }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="licenseNumber">
    <th mat-header-cell *matHeaderCellDef width="1">License<br />Number</th>
    <td mat-cell *matCellDef="let rosterMember">{{ rosterMember.license.number }}</td>
  </ng-container>
  <ng-container matColumnDef="residency">
    <th mat-header-cell *matHeaderCellDef width="1">Residency</th>
    <td mat-cell *matCellDef="let rosterMember" class="text-nowrap">{{ rosterMember.license.residencyText }}</td>
  </ng-container>
  <ng-container matColumnDef="issueDate">
    <th mat-header-cell *matHeaderCellDef width="1">Issue<br />Date</th>
    <td mat-cell *matCellDef="let rosterMember">{{ rosterMember.license.issueDate | date: 'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="expirationDate">
    <th mat-header-cell *matHeaderCellDef width="1">Expiration<br />Date</th>
    <td mat-cell *matCellDef="let rosterMember">{{ rosterMember.license.expirationDate | date: 'shortDate' }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row class="status-row" *matRowDef="let myRowData; columns: ['statusRow']; when: isHeaderRow"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>
