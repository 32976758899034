import { Component } from '@angular/core';

import { EventHistoryComponent } from './event-history.component';
import { Firm } from 'projects/common/src/lib/models/firm';
import { EventHistoryService } from 'projects/common/src/lib/services/api/event-history.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { AuditService } from 'projects/common/src/lib/services/api/audit.service';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';
import { FirmDetailService } from 'projects/common/src/lib/services/firm-detail.service';

@Component({
  selector: 'app-firm-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
})
export class FirmEventHistoryComponent extends EventHistoryComponent<Firm, FirmService> {
  constructor(
    protected eventHistoryService: EventHistoryService,
    protected notificationService: NotificationService,
    protected auditService: AuditService,
    protected firmDetailService: FirmDetailService
  ) {
    super(eventHistoryService, notificationService, auditService);
    this.detailService = firmDetailService;
  }
}
