import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PersonFull } from 'projects/common/src/lib/models/person-full';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';

@Injectable()
export class NcarbNumberGuard implements CanActivate {
  constructor(private notificationService: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let person = route.parent.data.person as PersonFull;
    if (!person) {
      return true; // must be a direct link, presume person has a record
    }
    let hasNcarbNumber = !!person.ncarbNumber;
    if (!hasNcarbNumber) {
      let message = person.name.firstLast + ' does not have an NCARB Record on file';
      this.notificationService.notifyFail(message);
    }
    return hasNcarbNumber;
  }
}
