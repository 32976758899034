import { UpdatableModel } from './updatable-model';
import { LookupItem } from './search/lookup-item';
import { Address } from './address';
import { Firm } from './firm';

export class FirmLookup extends UpdatableModel<FirmLookup> implements LookupItem {
  id: string;
  name: string;
  nameFull: string;
  publicAddress: Address;
  licenseNumber: string;
  licenseStatus: string;
  licenseType: string;
  licenseIssueDate: Date;
  isAddNew: boolean;

  update(init?: Partial<FirmLookup>) {
    super.update(init);
    if (this.publicAddress) {
      this.publicAddress = new Address(this.publicAddress);
    }
  }

  get lookupId() {
    return this.id;
  }
  get lookupName() {
    return this.name;
  }
  get lookupStatus() {
    return this.licenseStatus || 'Unlicensed';
  }
  get status() {
    return this.lookupStatus;
  }
  get lookupInfo() {
    let info = [this.licenseNumber, this.publicAddress && this.publicAddress.oneLine].filter(_ => !!_).join(' | ');
    return info;
  }

  public static FromFirm(firm: Firm): FirmLookup {
    return new FirmLookup({
      id: firm.id,
      name: firm.name,
      nameFull: firm.nameFull,
      publicAddress: firm.publicAddress,
      licenseNumber: firm.license?.number,
      licenseStatus: firm.license?.status,
      licenseType: firm.license?.type,
      licenseIssueDate: firm.license?.issueDate,
    });
  }
}
