import { Injectable } from '@angular/core';
import { Firm } from 'projects/common/src/lib/models/firm';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';
import { DetailService } from './detail.service';

@Injectable({
  providedIn: 'root',
})
export class FirmDetailService extends DetailService<Firm, FirmService> {
  constructor(protected firmService: FirmService) {
    super(firmService);
  }

  get firm$() {
    return this.entity$;
  }

  get firm() {
    return this.entity;
  }
}
