import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManualPaymentDialogue } from './manual-payment.dialogue';
import { ManualPayment } from 'projects/common/src/lib/models/manual-payment';

@Directive({
  selector: 'app-manual-payment, [app-manual-payment]',
  exportAs: 'confirm',
})
export class ManualPaymentDialogueDirective {
  @Input() name: string;
  @Input() isPerson: boolean;
  @Input() orderId: string;
  @Output() manualPayment = new EventEmitter<ManualPayment>();
  dialogRef: MatDialogRef<ManualPaymentDialogue>;
  constructor(private dialogue: MatDialog) {}

  open() {
    this.dialogRef = this.dialogue.open(ManualPaymentDialogue, {
      data: {
        name: this.name,
        isPerson: this.isPerson,
        orderId: this.orderId,
      },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.manualPayment.emit(result);
      }
    });
  }
}
