import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';

@Component({
  selector: 'app-firm-type-selector',
  templateUrl: './firm-type-selector.component.html',
  styleUrls: ['./firm-type-selector.component.scss'],
})
export class FirmTypeSelectorComponent {
  @HostBinding('class.selector') selectorClass = true;
  @Input() licenseType: string;
  @Input() hideLabel: boolean;
  @Input() includeLlc: boolean;
  @Output() change = new EventEmitter<string>();
  types = [
    { enum: '', abbr: 'All' },
    { enum: 'ProfessionalArchitectural', abbr: 'AC' },
    { enum: 'ArchitecturalEngineering', abbr: 'AE' },
    { enum: 'ArchitecturalFirm', abbr: 'AF' },
    { enum: 'LimitedLiability', abbr: 'LLC' },
  ];

  ngOnInit() {
    if (!this.includeLlc) {
      this.types = this.types.slice(0, -1);
    }
  }

  onChanged() {
    this.change.emit(this.licenseType);
  }
}
