import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventHistory } from 'projects/common/src/lib/models/event-history';

@Component({
  selector: 'app-event-history-note-dialogue',
  templateUrl: './event-history-note.dialogue.html',
  styleUrls: ['./event-history-note.dialogue.scss'],
})
export class EventHistoryNoteDialogue implements OnInit {
  subjectName: string;
  eventHistoryNote: EventHistory;
  constructor(@Inject(MAT_DIALOG_DATA) private data) {}

  ngOnInit() {
    this.eventHistoryNote =
      this.data.eventHistoryNote ||
      new EventHistory({
        subjectId: this.data.subjectId,
        title: '',
        text: '',
      });
    this.subjectName = this.data.subjectName;
  }
}
