<div>
  <div mat-dialog-header>
    <strong>Add New Payment</strong>
    <br />
    <h2>{{name}}</h2>
  </div>
  <div mat-dialog-content *ngIf="order; else loading">
    <fieldset>
      <legend>Fees</legend>
      <lib-fee-items [order]="order"></lib-fee-items>
      <div *ngIf="order.hasDelinquencyFee" class="pull-right">
        <mat-checkbox
          color="primary"
          name="overrideDelinquencyFee"
          [(ngModel)]="overrideDelinquencyFee"
          (click)="toggleDelinquencyFeeOverride()"
          >Override Delinquency Fee</mat-checkbox
        >
      </div>
    </fieldset>
    <fieldset>
      <legend>Payment Information</legend>
      <div class="flex-fields">
        <mat-radio-group [(ngModel)]="manualPayment.paymentType" required>
          <mat-radio-button [value]="'check'" color="primary"> Check </mat-radio-button>
          <mat-radio-button [value]="'moneyorder'" color="primary"> Money Order </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="flex-fields flex-fields-gray check-info">
        <mat-form-field appearance="standard">
          <mat-label>Name on Check</mat-label>
          <input matInput [(ngModel)]="manualPayment.nameOnCheck" required />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Check Number</mat-label>
          <input matInput [(ngModel)]="manualPayment.checkNumber" required />
        </mat-form-field>
      </div>
    </fieldset>
  </div>
  <ng-template #loading>
    <div mat-dialog-content>
      <p style="padding-bottom: 260px; width: 300px">Loading...</p>
    </div>
  </ng-template>
  <p mat-dialog-actions>
    <button mat-button type="submit" [mat-dialog-close]="manualPayment" color="primary">Add Payment</button>
    <button mat-button type="button" [mat-dialog-close]="false" color="accent" (click)="onClose()">Cancel</button>
  </p>
</div>
