import { FormBuilder, FormGroup } from '@angular/forms';
import { Phone } from './phone';
import { WorkHistoryBase } from './work-history-base';
import { isMoment } from 'moment';
import { FirmLookup } from './firm-lookup';

// TODO refactor WorkHistoryForArchApp out of arch app review
// and consolilate to just WorkHistory
export class WorkHistory extends WorkHistoryBase<WorkHistory> {
  startDate?: Date;
  endDate?: Date;

  acceptPatch(formGroup: FormGroup) {
    var startDate = formGroup.controls.startDate.value;
    if (startDate && isMoment(startDate)) {
      formGroup.controls.startDate.setValue(startDate.format());
    }
    var endDate = formGroup.controls.endDate.value;
    if (endDate && isMoment(endDate)) {
      formGroup.controls.endDate.setValue(endDate.format());
    }
    this.update(formGroup.value);
  }

  patch(formGroup: FormGroup, firmLookup: FirmLookup) {
    formGroup.patchValue(this);
    if (this.phone) {
      this.phone.patch(formGroup.controls.phone as FormGroup);
    }
    formGroup.controls.firmLookup.setValue(firmLookup);
    formGroup.controls.currentlyWorkHere.setValue(!this.endDate);
    if (!this.endDate && !!this.startDate) {
      formGroup.controls.endDate.disable();
    }
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      email: '',
      title: '',
      phone: Phone.GetFormGroup(formBuilder),
      firmLookup: '',
      startDate: '',
      endDate: '',
      currentlyWorkHere: '',
    });
  }
}
