<div mat-dialog-title>
  {{eventHistoryNote.id ? 'Edit' : 'Add'}} Note
  <h2>{{subjectName}}</h2>
</div>
<div mat-dialog-content>
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>Subject</mat-label>
      <input #titleInput matInput [(ngModel)]="eventHistoryNote.title" required />
    </mat-form-field>
  </div>
  <div class="flex-fields">
    <mat-form-field appearance="standard" gray-textarea>
      <mat-label>Comment</mat-label>
      <textarea matInput [(ngModel)]="eventHistoryNote.text"></textarea>
    </mat-form-field>
  </div>
</div>
<p mat-dialog-actions class="reverse">
  <button mat-button type="submit" color="primary" [mat-dialog-close]="eventHistoryNote" [disabled]="!titleInput.value">
    Save
  </button>
  <button mat-button type="button" color="accent" [mat-dialog-close]="false">Cancel</button>
</p>
