import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EventHistory } from '../../models/event-history';

@Injectable({
  providedIn: 'root',
})
export class EventHistoryService {
  private path: string;

  constructor(private api: ApiService) {
    this.path = 'eventhistory';
  }

  get(id: string): Observable<EventHistory> {
    let path = this.path.concat('/', id);
    return this.api.get(path).pipe(map(data => new EventHistory(data)));
  }

  getForSubject(subjectId: string): Observable<EventHistory[]> {
    let qs = this.api.queryStringify({ subjectId });
    return this.api.get(this.path.concat(qs)).pipe(map(data => data.map(event => new EventHistory(event))));
  }

  getForAggregate(aggregateId: string): Observable<EventHistory[]> {
    let qs = this.api.queryStringify({ aggregateId: aggregateId });
    return this.api.get(this.path.concat(qs)).pipe(map(data => data.map(event => new EventHistory(event))));
  }

  add(eventHistory: EventHistory): Observable<EventHistory> {
    return this.api.post(this.path, eventHistory).pipe(map(data => new EventHistory(data)));
  }

  update(eventHistory: EventHistory): Observable<EventHistory> {
    let path = this.path.concat('/', eventHistory.id);
    return this.api.put(path, eventHistory).pipe(map(data => new EventHistory(data)));
  }

  delete(eventHistory: EventHistory): Observable<EventHistory> {
    let path = this.path.concat('/', eventHistory.id);
    return this.api.delete(path).pipe(map(data => new EventHistory(data)));
  }
}
