import { Component, Input, OnInit } from '@angular/core';
import { LicenseCertificateVariant, TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'license-buttons',
  templateUrl: './registration-aside.component.html',
  styleUrls: ['./registration-aside.component.scss'],
})
export class LicenseButtons implements OnInit {
  @Input() impersonate: () => {};
  @Input() download: () => {};
  @Input() sendLicense: () => {};
  @Input() canDownloadLicense: boolean;
  @Input() licenseCertificateVariants: LicenseCertificateVariant[];
  selectedVariant: LicenseCertificateVariant;

  ngOnInit() {
    if (this.showCertificateVariants()) this.selectedVariant = this.licenseCertificateVariants.find(cv => cv.isDefault);
  }

  showCertificateVariants(): boolean {
    return !!this.licenseCertificateVariants && this.licenseCertificateVariants.length > 0;
  }
}
