import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'projects/common/src/lib/services/api/payment.service';
import { ManualPayment } from 'projects/common/src/lib/models/manual-payment';
import { Order } from 'projects/common/src/lib/models/order';

@Component({
  selector: 'app-manual-payment-dialogue',
  templateUrl: './manual-payment.dialogue.html',
  styleUrls: ['./manual-payment.dialogue.scss'],
})
export class ManualPaymentDialogue implements OnInit {
  name: string;
  orderId: string;
  order: Order;
  amount: number;
  manualPayment: ManualPayment;
  overrideDelinquencyFee: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private paymentService: PaymentService
  ) {}

  async ngOnInit() {
    this.orderId = this.data.orderId;
    this.name = this.data.name;
    this.manualPayment = new ManualPayment({
      nameOnCheck: this.name,
      checkNumber: '',
      paymentType: 'check',
    });

    await this.paymentService.getOrderForCheckPayment(this.orderId).then(this.orderRefresh);
  }

  toggleDelinquencyFeeOverride() {
    this.overrideDelinquencyFee = !this.overrideDelinquencyFee;
    this.paymentService.toggleDelinquencyFee(this.orderId, this.overrideDelinquencyFee).subscribe(this.orderRefresh);
  }

  onClose() {
    if (this.overrideDelinquencyFee) {
      this.toggleDelinquencyFeeOverride();
    }
  }

  orderRefresh = (order: Order) => {
    this.order = order;
    this.overrideDelinquencyFee = order.hasDelinquencyFeeDiscount;
  };
}
