import { Component } from '@angular/core';
import { NcarbAuthService } from '../../shared/services/ncarb-auth.service';
@Component({
  selector: 'app-ncarb-login',
  templateUrl: './ncarb-login.component.html',
  styleUrls: ['./ncarb-login.component.scss'],
})
export class NcarbLoginComponent {
  loggedIn: boolean;
  constructor(ncarbAuthService: NcarbAuthService) {
    ncarbAuthService.receiveToken();
    this.loggedIn = ncarbAuthService.isAuthenticated();
    if (window.frameElement && !ncarbAuthService.isAuthenticated()) {
      ncarbAuthService.login();
    }
  }
}
