import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { LicenseStatusSummary } from 'projects/common/src/lib/models/license-status-summary';
import { of } from 'rxjs';

@Component({
  selector: 'app-license-status-summary',
  templateUrl: 'license-status-summary.component.html',
  styleUrls: ['license-status-summary.component.scss'],
})
export class LicenseStatusSummaryComponent {
  @Input() data: LicenseStatusSummary[];
  @Output() select = new EventEmitter<string>();

  constructor(private settings: TenantSettings) {}

  get data$() {
    return of(this.data);
  }

  getStatusCss(status: string): string {
    return this.settings.getCustomStatusCssClass(status, 'status-');
  }

  onStatusSelect(stat: LicenseStatusSummary) {
    this.select.emit(stat.status);
  }
}
