import { UpdatableModel } from '../updatable-model';

export class NcarbOverviewItem extends UpdatableModel<NcarbOverviewItem> {
  category: string;
  isSatisfied: boolean;
  title: string;
  details: string[];
  errors: string[];
  hasCurrentApplication: boolean;

  update(init?: Partial<NcarbOverviewItem>) {
    super.update(init);

    this.details = [];
    if (init && init.details) {
      for (let detail of init.details) {
        this.details.push(detail);
      }
    }

    this.errors = [];
    if (init && init.errors) {
      for (let error of init.errors) {
        this.errors.push(error);
      }
    }
  }

  get isSatifiedAndNoErrors() {
    return this.isSatisfied && !this.errors.length;
  }
}
