import { Injectable } from '@angular/core';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import { Confirmations, JurisdictionConfirmationService, LegendWithNote } from './jurisdiction-confirmation-service';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { ArchitectApplication } from '../../../models/applications/architect-application';

@Injectable({
  providedIn: 'root',
})
export class NEBOGConfirmationService extends JurisdictionConfirmationService {
  attestations = [
    'The information I have provided on this form is true and accurate to the best of my knowledge.',
    'I attest to being the applicant, I am not an agent acting on behalf of the applicant.',
  ];

  getArchitectConfirmations(application: ArchitectApplication): Confirmations {
    if (application.applicationVariant === 'ArchitectInitial' || application.applicationVariant === 'ArchitectReciprocal') {
      return {
        laws: [],
        attestations: [
          ...this.attestations,
          `I will not represent myself as a Geologist or offer to perform Geologist services in the
          State of Nebraska until this application is approved and a professional Geologist license has been granted
          by the Nebraska Board of Geologists.`,
        ],
      };
    }

    if (
      application.applicationVariant === 'ArchitectRenewal' ||
      application.applicationVariant === 'ArchitectEmeritusRenewal'
    ) {
      return {
        laws: [],
        attestations: this.attestations,
      };
    }

    return {
      laws: [
        '<a target="_blank" href="https://nebog.nebraska.gov/sites/default/files/doc/NEBOGtitle171.pdf">Nebraska Geologist\'s Code of Practice</a>',
        '<a target="_blank" href="https://nebog.nebraska.gov/sites/default/files/doc/NEBOGHandbook.pdf">Nebraska Professional Geologists Handbook</a>',
      ],
      attestations: [
        ...this.attestations,
        "I will not represent myself as a professional geologist or offer to perform engineering services in the State of Nebraska until this application is approved and a professional geologist's license has been granted by the Board.",
        'Unless my firm holds a current Certificate of Authorization, it is not authorized to offer or contract to perform services in Nebraska.',
      ],
    };
  }

  getShareHolderNote() {
    return `We hereby certify that the following listed geologist are licensed in the state of ${this.settings.stateName} and own the number of shares listed
    (no percentages) and that the following number of total shares is correct. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Nebraska. R.S. 12:3456(A)</a>
    which requires that a majority stock in a professional geologists corporation must be owned by the individually licensed ${this.settings.stateName} geologists.`;
  }

  getBoardOfDirectorsNote() {
    return `We hereby certify that the following listed geologists are licensed in the state of ${this.settings.stateName} and serve as Directors of the corporation. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Nebraska. R.S. 12:3456</a>
    which requires that a majority of the board of directors, if more than two, shall be licensed ${this.settings.stateName} geologists or, if there are less than three directors, at least one must be a licensed ${this.settings.stateName} geologist.`;
  }

  getFirmConfirmations(application: FirmApplicationFull): Confirmations {
    const laws = [
      `${application.licenseTypeName ?? application.name} (<a href="${
        this.constants.commonLinks.rules
      }" target="_blank">Nebraska Rev. Stat. 81-3528 and Title 171 NAC, Chapter 7</a>)`,
    ];

    return {
      laws,
      attestations: this.attestations,
    };
  }

  getSupervisorNotes(application: FirmApplication): LegendWithNote[] {
    return [
      {
        legend: 'Designated Individual(s) in Responsible Charge',
        note: `The above individuals are hereby designated as the individuals in responsible charge according to
          <a href="${this.constants.commonLinks.rules}" target="_blank">Nebraska Rev. Stat. 81-3528 and Title 171 NAC, Chapter 7</a>:`,
      },
    ];
  }
}
