import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfessionalReference } from 'projects/common/src/lib/models/professional-reference';

@Component({
  selector: 'app-professional-reference-dialog',
  templateUrl: './professional-reference.dialog.html',
  styleUrls: ['./professional-reference.dialog.scss'],
})
export class ProfessionalReferenceDialog implements OnInit {
  subjectName: string;
  professionalReference: ProfessionalReference;
  constructor(@Inject(MAT_DIALOG_DATA) private data) {}

  ngOnInit() {
    this.professionalReference = this.data.professionalReference;
  }
}
