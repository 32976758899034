import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { flatMap, map, startWith } from 'rxjs/operators';
import { EventHistory } from 'projects/common/src/lib/models/event-history';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { ListComponent } from 'projects/common/src/lib/components/list';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';

@Component({
  selector: 'app-event-history-list',
  templateUrl: './event-history-list.component.html',
  styleUrls: ['./event-history-list.component.scss'],
})
export class EventHistoryListComponent extends ListComponent<EventHistory> {
  @Input() eventHistoryType$: BehaviorSubject<string>;
  @Input() subjectName: string;
  @Output() editEvent = new EventEmitter<EventHistory>();
  @Output() deleteEvent = new EventEmitter<EventHistory>();
  columns = ['icon', 'title', 'date', 'time', 'actor', 'comments', 'expand'];
  constructor(
    private authService: AuthService,
    private constants: JurisdictionConstants,
    private notificationService: NotificationService,
    private applicationService: ArchitectApplicationService
  ) {
    super();
  }

  ngOnInit() {
    let loading = {} as EventHistory;
    loading['loading'] = true;
    loading['id'] = 'loading';

    this.listToUse$ = this.list$.pipe(
      startWith([loading]),
      flatMap(list =>
        this.eventHistoryType$.pipe(
          map(type => {
            let newList = list.filter(l => !type || l.type == type);
            let startAt = 0;
            if (!newList.length) {
              let noItems = {} as EventHistory;
              noItems['noItems'] = true;
              noItems.id = type + 'none';
              newList.push(noItems);
              startAt = 1;
            }
            let hidden = list.filter(l => type && l.type != type);
            for (var i = startAt; i < hidden.length; i++) {
              let hideMe = {} as EventHistory;
              hideMe['hideMe'] = true;
              hideMe['id'] = hidden[i].id + 'hidden';
              newList.push(hideMe);
            }
            return newList;
          })
        )
      )
    );
    super.ngOnInit();
  }

  trackByEventId(index: number, item: EventHistory) {
    return item.id + item.updatedOn.toString();
  }

  isUserActor(event: EventHistory) {
    return event.actorId == this.authService.claims.actorId;
  }

  edit(eventHistory: EventHistory) {
    this.editEvent.emit(eventHistory);
  }

  delete(eventHistory: EventHistory) {
    this.deleteEvent.emit(eventHistory);
  }

  adjustEventTitle(eventTitle: string): string {
    return eventTitle && this.constants.historyLogEventTitleArchitectReplacement
      ? eventTitle.replace('Architect', this.constants.historyLogEventTitleArchitectReplacement)
      : eventTitle;
  }

  resendReadyToApplyEmail(event: EventHistory) {
    this.applicationService.sendInvite(event.aggregateRootId, event.subjectId).subscribe(_ => {
      this.notificationService.notifySuccess('Ready to Apply email re-sent');
    });
  }

  hideMe = (index, item) => item.hideMe;
  loading = (index, item) => this.isLoading;
}
