import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { NcarbProfile } from '../../ncarb/ncarb-profile';
import { MatDialog } from '@angular/material/dialog';
import { NcarbLoginDialogue } from '../../ncarb/ncarb-login-dialogue/ncarb-login-dialogue';
import { Observable, of, ReplaySubject } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { NcarbSettings } from 'projects/common/src/lib/services/api/NcarbSettings';

@Injectable({
  providedIn: 'root',
})
export class NcarbAuthService {
  private profile: NcarbProfile;
  private profilePromise: Promise<NcarbProfile>;
  private dialogResult$: ReplaySubject<boolean>;
  constructor(
    private auth: OAuthService,
    ncarb: NcarbSettings,
    private dialog: MatDialog
  ) {
    auth.configure(ncarb.auth);
    auth.setStorage(localStorage);
    auth.tokenValidationHandler = new JwksValidationHandler();
    window.addEventListener('storage', e => {
      if (e.key === 'access_token') {
        setTimeout(() => this.dialog.closeAll(), 1000);
      }
    });
    this.dialog.afterAllClosed.subscribe(_ => {
      if (this.dialogResult$) {
        this.dialogResult$.next(this.isAuthenticated());
      }
    });
  }

  receiveToken() {
    this.auth.tryLogin();
  }

  login(): void {
    this.auth.initImplicitFlow();
  }

  logout(): void {
    this.auth.logOut();
  }

  isAuthenticated(): boolean {
    return this.auth.hasValidAccessToken();
  }

  //dead code?
  getProfile(): Promise<NcarbProfile> {
    if (this.profile) {
      return new Promise(resolve => resolve(this.profile));
    }
    if (this.profilePromise) {
      return this.profilePromise;
    }
    return (this.profilePromise = this.auth.loadUserProfile().then(profile => {
      this.profile = new NcarbProfile(profile);
      return this.profile;
    }));
  }

  openDialogue() {
    this.dialog.open(NcarbLoginDialogue, {
      minWidth: '430px',
      minHeight: '430px',
    });
  }

  tryLogin(): Observable<boolean> {
    if (this.isAuthenticated()) {
      return of(true);
    }
    this.openDialogue();

    // observable set when dialog closes, including when access_token changed
    // see constructor closeAll subscription and localstorage eventlistener
    this.dialogResult$ = new ReplaySubject<boolean>(1);
    return this.dialogResult$.pipe(
      timeout(1000 * 60) // wait one minute for successful login
    );
  }
}
