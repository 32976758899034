import { Component } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { ListComponent } from 'projects/common/src/lib/components/list';
import { ActivatedRoute } from '@angular/router';
import { getPersonOrFirmId } from 'projects/common/src/lib/utility/router-extensions';

@Component({
  selector: 'app-moral-character',
  templateUrl: './moral-character.component.html',
  styleUrls: ['./moral-character.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MoralCharacterComponent extends ListComponent<QuestionnaireResponse> {
  columns = ['reported', 'type', 'answers', 'expand'];
  aggregateRootId: string;
  constructor(protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.aggregateRootId = getPersonOrFirmId(this.route);
  }

  noAnswers = (index, item: QuestionnaireResponse) => !item.answers.length;
}
