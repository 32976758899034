import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { NcarbAuthService } from '../../shared/services/ncarb-auth.service';

@Component({
  selector: 'app-ncarb-silent-load',
  templateUrl: './ncarb-silent-load.component.html',
  styleUrls: ['./ncarb-silent-load.component.scss'],
})
export class NcarbSilentLoadComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() tryOncePerDay: boolean;
  ncarbSilentLoadUrl = '/ncarb';
  doAttempt: boolean;

  constructor(public ncarbAuth: NcarbAuthService) {}

  ngOnInit() {
    if (this.ncarbAuth.isAuthenticated()) {
      return;
    }
    this.doAttempt = true;
    if (this.tryOncePerDay) {
      if (localStorage.lastNcarbDailyAttempt) {
        var lastNcarbDailyAttempt = moment(JSON.parse(localStorage.lastNcarbDailyAttempt));
        this.doAttempt = lastNcarbDailyAttempt.startOf('day').valueOf() != moment().startOf('day').valueOf();
      }
      if (this.doAttempt) {
        localStorage.lastNcarbDailyAttempt = JSON.stringify(moment());
      }
    }
  }
}
