import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizingComponent } from 'projects/common/src/lib/components/authorizing/authorizing.component';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { Router } from '@angular/router';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-staff-authorizing',
  templateUrl: './staff-authorizing.component.html',
  styleUrls: ['./staff-authorizing.component.scss'],
})
export class StaffAuthorizingComponent extends AuthorizingComponent implements OnInit, OnDestroy {
  constructor(auth: AuthService, router: Router, accountService: AccountService, settings: TenantSettings) {
    super(auth, router, settings, accountService);
  }
  ngOnInit(): void {
    this.toLandingPageIfNotAuthenticated();
  }

  ngOnDestroy(): void {
    this.onAuthSubscription.unsubscribe();
  }
}
