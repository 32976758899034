import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NcarbAuthService } from 'src/app/shared/services/ncarb-auth.service';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ncarb-offer-login',
  templateUrl: './ncarb-offer-login.component.html',
  styleUrls: ['./ncarb-offer-login.component.scss'],
  exportAs: 'offerLogin',
})
export class NcarbOfferLoginComponent extends BaseComponent {
  @Input() label = 'NCARB information';
  @Output() loggedIn = new EventEmitter();
  offerLogin = false;
  hasNcarbNumber = false;
  name: string;

  constructor(
    private ncarbAuthService: NcarbAuthService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.name = this.route.snapshot.parent.data.person.name.firstLast;
    this.hasNcarbNumber = !!this.route.snapshot.parent.data.person.ncarbNumber;
    this.offerLogin = this.hasNcarbNumber && !this.ncarbAuthService.isAuthenticated();
  }

  openDialogue() {
    this.unsubOnDestroy = this.ncarbAuthService.tryLogin().subscribe(success => {
      if (success) {
        this.offerLogin = false;
        this.loggedIn.emit();
      }
    });
    return false;
  }

  get loggedInWithNcarbNumber() {
    return !this.offerLogin && this.hasNcarbNumber;
  }
}
