import { Component, Input, HostBinding } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { EventHistoryService } from 'projects/common/src/lib/services/api/event-history.service';
import { EventHistory } from 'projects/common/src/lib/models/event-history';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { AuditService } from 'projects/common/src/lib/services/api/audit.service';
import { DetailService } from 'projects/common/src/lib/services/detail.service';
import { IEntityService } from 'projects/common/src/lib/services/api/i-entity.service';

@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
})
export class EventHistoryComponent<TEntity, TEntityService extends IEntityService<TEntity>> extends BaseComponent {
  @HostBinding('class.event-history-component') toggleComponentClass = true;
  @Input() id: string;
  _id: string; // async id is shakey
  @Input() subjectName: string;
  pulse$ = new ReplaySubject<boolean>(1);
  events$: Observable<EventHistory[]>;
  type$ = new BehaviorSubject<string>('');
  eventHistoryTypes = ['', 'SystemEvent', 'Note', 'Audit'];
  detailService: DetailService<TEntity, TEntityService>;
  constructor(
    protected eventHistoryService: EventHistoryService,
    protected notificationService: NotificationService,
    protected auditService: AuditService
  ) {
    super();
  }

  ngOnInit() {
    this._id = this.id;

    let doublePulse = false;
    this.unsubOnDestroy = this.detailService.entity$.subscribe(_ => {
      this._id = _['id'];
      this.pulse$.next(true);
      if (doublePulse) {
        setTimeout(_ => this.pulse$.next(true), 5000);
      } else {
        doublePulse = true;
      }
    });

    this.events$ = this.pulse$.pipe(
      switchMap(_ =>
        forkJoin(this.eventHistoryService.getForAggregate(this._id), this.auditService.getForAggregate(this._id)).pipe(
          map(([events, audits]) => [...events, ...audits].sort((a, b) => (a.happenedOn < b.happenedOn ? 1 : -1)))
        )
      )
    );
  }

  switchType(type) {
    if (type != this.type$.value) {
      this.type$.next(type);
    }
  }

  onNoteAction(event: EventHistory, action) {
    let method = this.eventHistoryService[action];
    var doing = action.replace(/e$/, '') + 'ing';
    doing = doing[0].toUpperCase() + doing.substring(1);
    var done = action.replace(/e$/, '') + 'ed';
    this.notificationService.notifyWait(doing + ' note');
    this.unsubOnDestroy = method.call(this.eventHistoryService, event).subscribe(_ => {
      this.notificationService.notifySuccess('Note ' + done);
      this.pulse$.next(true);
    });
  }
}
