<table mat-table [dataSource]="data$">
  <ng-container matColumnDef="status">
    <td mat-cell *matCellDef="let stat">
      <span class="status-word" [ngClass]="getStatusCss(stat.status)">
        {{ stat.statusLabel | spaceCapCase }}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="count">
    <td width="1" mat-cell *matCellDef="let stat">
      {{ stat.count }}
    </td>
  </ng-container>
  <tr
    (click)="onStatusSelect(stat)"
    mat-row
    class="status-row stat-row"
    *matRowDef="let stat; columns: ['status', 'count']"
  ></tr>
</table>
