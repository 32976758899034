import { UpdatableModel } from '../updatable-model';

export class WorkHistory extends UpdatableModel<WorkHistory> {
  employer: WorkHistoryEmployer;
  start: Date;
  end: Date;
  status: string;
  title: string;
}

export class WorkHistoryEmployer extends UpdatableModel<WorkHistoryEmployer> {
  name: string;
}
