<lib-confirm #confirmSendLicense="confirm" yesText="Yes, continue" noText="No, cancel"></lib-confirm>

<button mat-button *ngIf="impersonate" class="alt auto capitalize" (click)="impersonate()">Start Application</button>

<div *ngIf="showCertificateVariants() && canDownloadLicense" class="certificateVariantSelectorOuterWrapper">
  <div class="certificateVariantSelectorInnerWrapper">
    <mat-form-field appearance="standard" class="certificateVariantSelectorMatField">
      <mat-select [(ngModel)]="selectedVariant" class="certificateVariantSelector">
        <mat-option *ngFor="let _variant of licenseCertificateVariants" [value]="_variant">
          {{ _variant.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button class="auto alt" type="button" (click)="download(selectedVariant?.name ?? '')">Download License</button>
  </div>
</div>

<button *ngIf="!showCertificateVariants() && canDownloadLicense" mat-button class="auto alt" type="button" (click)="download()">
  Download License
</button>

<button mat-button class="alt auto capitalize" (click)="sendLicense()">Send License</button>
